import React, { useEffect, useMemo, useRef, useState } from "react";
import { applicationType } from "../../applicationType";
import localizations from "../../localizations.json";
import { ripleWhiteButton } from "../../libs/ripple";
import { arrowDownSelectHeader, greenCheck } from "../../libs/images";
import Tippy from "@tippyjs/react";
import { tippyPrefs } from "../../modules/modulesConfig";
import MaterialDropdown from "../../modules/MaterialDropdown";
import SearchComponent from "./SearchComponent";
import { UseFetchGet, UseFetchPost } from "../../hooks/fetchFunctions";
import ChatUserCard from "./ChatUserCard";
import Chat from "../Chat/Chat";
import AddUsersChat from "./AddUsersChat";
import FunnerHeader from "./FunnerHeader";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  Zoom,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { phoneRegex } from "../../libs/regexes";
import { IsJsonString } from "../../libs/helpers";
import { enqueueSnackbar } from "notistack";

let isContactsLoading = false;
let reconnectListenerSet = false;
function FunnerGlobalMessanger({
  iframeMode,
  openUnreadMessagesPopup,
  chatUsersFunner,
  chatUsersFunnerRef,
  chatUsersGlobal,
  chatUsersGlobalRef,
  getContactsQueryParamsRef,
  getCompanyChatUsersGlobal,
  selectedFunnerChatUser,
  selectedFunnerChatUserRef,
  handleSelectedFunnerChatUser,
  updateFunnerChatUsersStates,
  updateChatUser,
  isNavOpen,
  userData,
  userDataRef,
  userConfig,
  userConfigRef,
  businessInfo,
  funnerUsers,
  chatUsersLabelsList,
  updateUserConfig,
  handleSetNotif,
  toggleUnreadMessagesPopup,
  templatesList,
  templatesLoading,
  fetchWhatsappTemplates,
  connection,
  isConnection,
  setTotalUnredMessages,
  setTotalUnreadChats,
  handleUsersFireberrySettingsWindow,
  setSelectedUsersFireberrySync,
  handleUsersPrioritySettingsWindow,
  setSelectedUsersPrioritySync,
  tabActiveRef,
  handlePrioritySendDocsWindow,
  sendUploadPriority,
  resetSendUploadPriority,
  chatUsersMessagesRef,
  userInSettingsCreationState,
  handleChatUserMessages,
  disableChatGoBack,
  clearChatUsersMessages,
  getChatMissingHistory,
  isMyUsersChats,
  setMyUsersChat,
  goToFavouriteMessageState,
  goToFavouriteMessageStateRef,
  handleGoToFavouriteMessageState,
}) {
  let isChatOpenLocalStorage = JSON.parse(
    localStorage.getItem("globalFunnerMessangerOpenStatus"),
  );
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
  const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

  //! User info
  // const [selectedUserId, setSelectedUserId] = useState(null);
  // const [selectedChatUser, setSelectedChatUser] = useState(null);

  //! Config
  // const [isMyUsersChats, setMyUsersChat] = useState(false);
  // const [chatUsers, setChatUsers] = useState({
  //   myChats: [],
  //   notMyChats: [],
  // });
  const [unreadChatUsers, setUnreadChatUsers] = useState([]);
  const [selectedChatUsersList, setSelectedChatUsersList] = useState([]);
  const [isContactsLoaded, setContactsLoaded] = useState(false);

  const {
    chatUsersCustomizationSettings,
    chatUsersCustomizationAdditionalSettings,
  } = useMemo(() => {
    const platformsObject = IsJsonString(businessInfo.CompanyPlatforms)
      ? JSON.parse(businessInfo.CompanyPlatforms)
      : businessInfo.CompanyPlatforms;

    return {
      chatUsersCustomizationSettings:
        platformsObject?.chatUsersCustomizationSettings || [],
      chatUsersCustomizationAdditionalSettings:
        platformsObject?.chatUsersCustomizationAdditionalSettings || {},
    };
  }, [businessInfo?.CompanyPlatforms]);

  //! Filters and hints
  const [getContactsQueryParams, setGetContactsQueryParams] = useState({
    pageOffset: 1,
    searchQuery: "",
    label: "",
    open: "all",
    isOnlyUnread: false,
    assigneeFilter: [],
  });
  const [unreadHint, setUnreadHint] = useState({
    enabled: false,
    count: 0,
  });

  //! Views
  const [showUsersCreateChat, setShowUsersCreateChat] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [additionalMenuAnchorEl, setAdditionalMenuAnchorEl] = useState({
    anchor: null,
    chatUser: null,
  });

  //! Refs
  const chatUsersListRef = useRef(null);
  // const getContactsQueryParamsRef = useRef({
  //   pageOffset: 1,
  //   searchQuery: "",
  //   label: "",
  //   open: "all",
  //   isOnlyUnread: false,
  //   assigneeFilter: [],
  // });
  // const chatUsersRef = useRef({
  //   myChats: [],
  //   notMyChats: [],
  // });
  const chatUserContextRef = useRef(null);
  const unreadHintRef = useRef({
    enabled: false,
    count: 0,
  });
  const selectedChatUsersListRef = useRef([]);
  const lastSelectedUserIdRef = useRef(null);
  const textareaRef = useRef(null);

  //! Functions
  //* Filters
  const handleGetContactsParams = (params) => {
    getContactsQueryParamsRef.current = {
      ...getContactsQueryParamsRef.current,
      ...params,
    };
    setGetContactsQueryParams((prevObj) => ({ ...prevObj, ...params }));
  };

  const showAvatar = userConfig.isAvatarActive ?? true;

  //* Chat Users List
  const goToTopTrigger = useScrollTrigger({
    target: chatUsersListRef.current ? chatUsersListRef.current : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  function scrollChatUsersTop() {
    chatUsersListRef.current.scrollTop = 0;
  }
  function handleLoadContactsOnScroll(e) {
    const scrollContainer = e.target;
    const isAtBottom =
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
      scrollContainer.scrollHeight - 250;

    updateUnreadChatsIndicator();
    // handleChatUsersGoTopBtn();

    if (
      !isContactsLoading &&
      isAtBottom &&
      chatUsersFunner.notMyChats.length /
        getContactsQueryParamsRef.current.pageOffset ===
        30
    ) {
      // offsetContactsPage++;
      console.log("query on scroll");
      handleGetContactsParams({
        pageOffset: getContactsQueryParamsRef.current.pageOffset + 1,
      });
      getCompanyChatUsers();
    }
  }
  function updateUnreadChatsIndicator() {
    let unreadCount = 0;
    unreadChatUsers.forEach((chatUser) => {
      if (
        chatUsersListRef.current &&
        100 * chatUser.index < chatUsersListRef.current.scrollTop
      )
        unreadCount += chatUser.user.UnreadMessagesNumber;
    });

    if (unreadCount !== unreadHintRef.current.count) {
      //   console.log("set new unread hint state");
      setUnreadHint({
        enabled: unreadCount > 0,
        count: unreadCount,
      });
      unreadHintRef.current = {
        enabled: unreadCount > 0,
        count: unreadCount,
      };
    }
  }
  // function updateChatUsersStates(myChats, notMyChats) {
  //   setChatUsers((prevObj) => {
  //     return {
  //       ...prevObj,
  //       notMyChats: notMyChats,
  //       myChats: myChats,
  //     };
  //   });

  //   chatUsersRef.current.notMyChats = notMyChats;
  //   chatUsersRef.current.myChats = myChats;
  // }
  const handleAdditionalMenuClick = (e, chatUser) => {
    setAdditionalMenuAnchorEl({
      anchor: e.currentTarget,
      chatUser: chatUser,
    });
  };
  const handleAdditionalMenuClose = () => {
    setAdditionalMenuAnchorEl({
      anchor: null,
      chatUser: null,
    });
  };
  async function makeChatUserUnread(user) {
    handleAdditionalMenuClose();

    console.log(user);
    let lastIncomingMessageId = null;
    if (user.incomingLastMessageId) {
      lastIncomingMessageId = user.incomingLastMessageId;
    } else {
      const whatsappPhoneNumber = user.UserDisplayPhoneNumber;
      const res = await UseFetchGet(
        `/api/whatsapp/getChatUserMsgHistoryActvStatus/${whatsappPhoneNumber}/1/50`,
        undefined,
        null,
        undefined,
        "history",
      );

      if (res.data) {
        const lastIncomingMessage = res.data.messages.find(
          (msg) => msg.Type === "incoming",
        );
        lastIncomingMessageId = lastIncomingMessage && lastIncomingMessage.Id;
      }
    }

    if (lastIncomingMessageId) {
      const params = {
        id: Number(lastIncomingMessageId),
      };
      try {
        const response = await UseFetchPost(
          "/api/whatsapp/SetMessageUnread",
          params,
          "history",
        );
        console.log("unreadMessage response:", response);
        updateChatUser(user.UserDisplayPhoneNumber, {
          UnreadMessagesNumber: user.UnreadMessagesNumber + 1,
        });

        setTotalUnredMessages((prevValue) => prevValue + 1);
        setTotalUnreadChats((prevValue) => prevValue + 1);
      } catch (error) {
        console.log("Failed unread message", error);
      }
    }
  }
  const makeSingleChatUserRead = async (id) => {
    try {
      handleAdditionalMenuClose();
      const params = {
        bulkActionName: "setRead",
        chatUsersList: [id],
      };
      const res = await UseFetchPost(
        "/api/services/bulkChatUserActions",
        params,
        "history",
      );
      console.log(res);
    } catch (error) {}
  };
  const openFireberrySyncUser = (chatUser) => {
    handleAdditionalMenuClose();

    handleUsersFireberrySettingsWindow(true);
    setSelectedUsersFireberrySync([chatUser]);
  };
  const openPrioritySyncUser = async (chatUser) => {
    handleAdditionalMenuClose();

    handleUsersPrioritySettingsWindow(true);
    setSelectedUsersPrioritySync([chatUser]);
  };
  const handleAddUserToSelectedList = (user) => {
    setSelectedChatUsersList((prevList) => [...prevList, user]);
    selectedChatUsersListRef.current.push(user);
    lastSelectedUserIdRef.current = user.UserDisplayPhoneNumber;
  };
  const handleRemoveUserFromSelectedList = (user, source) => {
    setSelectedChatUsersList((prevList) =>
      prevList.filter((item) => item.Id !== user.Id),
    );
    selectedChatUsersListRef.current = selectedChatUsersListRef.current.filter(
      (item) => item.Id !== user.Id,
    );

    if (source === "ctrl") {
      lastSelectedUserIdRef.current =
        selectedChatUsersListRef.current[
          selectedChatUsersListRef.current.length - 1
        ]?.UserDisplayPhoneNumber || null;
    }
  };
  const handleSetSelectedUsersList = (list) => {
    setSelectedChatUsersList(list);
    selectedChatUsersListRef.current = list;
    lastSelectedUserIdRef.current = list.length
      ? list[list.length - 1].UserDisplayPhoneNumber
      : null;
  };

  //* Chat functions
  const manageSelectedChatUsersWithKeys = (e) => {
    const keyPressedCode = e.keyCode;
    if (
      selectedChatUsersListRef.current.length > 0 &&
      e.shiftKey &&
      (keyPressedCode === 40 || keyPressedCode === 38)
    ) {
      console.log(e);
      const isUp = keyPressedCode === 38;
      const isDown = keyPressedCode === 40;
      const currentUsersList =
        chatUsersFunnerRef.current[isMyUsersChats ? "myChats" : "notMyChats"];
      const lastSelectedUserIndex = currentUsersList.findIndex(
        (user) => user.UserDisplayPhoneNumber === lastSelectedUserIdRef.current,
      );
      const selectedUsersIndexesInList = selectedChatUsersListRef.current.map(
        (user) => currentUsersList.findIndex((item) => item.Id === user.Id),
      );

      console.log("lastSelectedUserIndex: ", lastSelectedUserIndex);
      console.log("selectedUsersIndexesInList: ", selectedUsersIndexesInList);

      let nextUser;
      let nextUserIndex = -1;
      if (isDown) {
        console.log("arrow down + shift");
        const isFirstElement =
          selectedChatUsersListRef.current.length !== 1 &&
          selectedUsersIndexesInList.some(
            (itemIndex) => itemIndex > lastSelectedUserIndex,
          );
        console.log("isFirstElement: ", isFirstElement);
        nextUserIndex = isFirstElement
          ? lastSelectedUserIndex
          : lastSelectedUserIndex + 1;
        nextUser = currentUsersList[nextUserIndex];
      } else if (isUp) {
        console.log("arrow up + shift");
        const isLastElement =
          selectedChatUsersListRef.current.length !== 1 &&
          selectedUsersIndexesInList.some(
            (itemIndex) => itemIndex < lastSelectedUserIndex,
          );
        console.log("isLastElement: ", isLastElement);
        nextUserIndex = isLastElement
          ? lastSelectedUserIndex
          : lastSelectedUserIndex - 1;
        nextUser = currentUsersList[nextUserIndex];
      }

      if (nextUser) {
        const isNextElemInList = selectedChatUsersListRef.current.some(
          (user) => nextUser.Id === user.Id,
        );

        if (isNextElemInList) {
          handleRemoveUserFromSelectedList(nextUser);
          const resultSortedUsers = selectedUsersIndexesInList
            .filter((item) => item !== nextUserIndex)
            .sort((a, b) => a - b);
          console.log("resultSortedUsers: ", resultSortedUsers);
          if (isDown) {
            lastSelectedUserIdRef.current =
              currentUsersList[resultSortedUsers[0]].UserDisplayPhoneNumber;
          } else if (isUp) {
            lastSelectedUserIdRef.current =
              currentUsersList[
                resultSortedUsers[resultSortedUsers.length - 1]
              ].UserDisplayPhoneNumber;
          }
        } else {
          handleAddUserToSelectedList(nextUser);
        }
      }
    }
  };
  const onUserCardClick = (e, user) => {
    if (e.ctrlKey) {
      console.log("ctrlKey clicked");
      console.log(selectedChatUsersListRef.current);
      if (
        selectedChatUsersListRef.current.some((item) => item.Id === user.Id)
      ) {
        handleRemoveUserFromSelectedList(user, "ctrl");
      } else {
        handleAddUserToSelectedList(user);
      }
    } else if (e.shiftKey) {
      const currentUsersList =
        chatUsersFunnerRef.current[isMyUsersChats ? "myChats" : "notMyChats"];
      const currentSelectedUserIndex = currentUsersList.findIndex(
        (findUser) =>
          findUser.UserDisplayPhoneNumber === user.UserDisplayPhoneNumber,
      );
      const lastSelectedUserIndex = currentUsersList.findIndex(
        (findUser) =>
          findUser.UserDisplayPhoneNumber === lastSelectedUserIdRef.current,
      );
      const startIndex = Math.min(
        currentSelectedUserIndex,
        lastSelectedUserIndex,
      );
      const endIndex = Math.max(
        currentSelectedUserIndex,
        lastSelectedUserIndex,
      );
      const selectedUsers = currentUsersList.slice(startIndex, endIndex + 1);

      const selectedPhoneNumbers = new Set(
        selectedChatUsersListRef.current.map(
          (user) => user.UserDisplayPhoneNumber,
        ),
      );
      const newListPart = selectedUsers.filter(
        (newUser) => !selectedPhoneNumbers.has(newUser.UserDisplayPhoneNumber),
      );
      handleSetSelectedUsersList([
        ...selectedChatUsersListRef.current,
        ...newListPart,
      ]);
    } else {
      openChatByContact(user);
    }
  };
  function openChatByContact(user) {
    // setChatLoading(true);
    try {
      const phone = user.UserDisplayPhoneNumber;
      handleSelectedFunnerChatUser(user);
      handleSetSelectedUsersList([user]);
    } catch (error) {
      console.log("Open Chat Contact error: ", error);
      handleSelectedFunnerChatUser(null);
      handleSetSelectedUsersList([]);
    } finally {
      // setChatLoading(false);
    }
  }
  function closeChat() {
    handleSelectedFunnerChatUser(null);
    handleSetSelectedUsersList([]);
  }

  //! Async and fetch functions
  async function getCompanyChatUsers() {
    isContactsLoading = true;
    try {
      let params;
      if (getContactsQueryParamsRef.current.searchQuery.length) {
        params = {
          page_number: 30,
          page_offset: 0,
          filter_by_open: "all",
          order_by: "last_message_date",
          order_descending: true,
          search_term: getContactsQueryParamsRef.current.searchQuery,
          label: "",
          isOnlyUnread: false,
          assigneeFilter: [],
        };
      } else {
        params = {
          page_number: 30 * getContactsQueryParamsRef.current.pageOffset,
          page_offset: 0,
          filter_by_open: getContactsQueryParamsRef.current.open,
          order_by: "last_message_date",
          order_descending: true,
          search_term: getContactsQueryParamsRef.current.searchQuery,
          label: getContactsQueryParamsRef.current.label,
          isOnlyUnread: getContactsQueryParamsRef.current.isOnlyUnread,
          assigneeFilter: getContactsQueryParamsRef.current.assigneeFilter,
        };
      }

      const res = await UseFetchPost(
        "/api/services/GetCompanyChatUsersAll",
        params,
        "history",
      );
      if (res) {
        updateFunnerChatUsersStates(
          res.data?.myChatUsers?.chatUsers
            ? [...res.data.myChatUsers.chatUsers]
            : [],
          res.data?.allChatUsers?.chatUsers
            ? [...res.data.allChatUsers.chatUsers]
            : [],
        );

        return {
          notMyChats: res.data?.allChatUsers?.chatUsers
            ? [...res.data.allChatUsers.chatUsers]
            : [],
          myChats: res.data?.myChatUsers?.chatUsers
            ? [...res.data.myChatUsers.chatUsers]
            : [],
        };
      }
    } catch (err) {
      console.log("GetCompanyChatUsersAll error: ", err);
    } finally {
      isContactsLoading = false;
    }
  }
  async function funnerOnLoadActions() {
    handleGetContactsParams({
      open: userConfigRef.current.openCloseFilter,
      isOnlyUnread: userConfigRef.current.unreadFilter,
    });
    // getCompanyChatUsers();
    loadFunnerChatUsers();
    const globalUsersList = await getCompanyChatUsersGlobal();

    if (applicationType === "extension") {
      if (isChatOpenLocalStorage?.userId) {
        const currentUser = globalUsersList.notMyChats.find(
          (contact) =>
            contact.UserDisplayPhoneNumber === isChatOpenLocalStorage.userId,
        );
        if (currentUser) {
          openChatByContact(currentUser);
        }
      }
    } else if (applicationType === "web") {
      const getParams = new URLSearchParams(document.location.search);
      const phoneFromParams = getParams.get("phone");

      if (phoneFromParams) {
        const phone = phoneRegex.test(phoneFromParams)
          ? "972" + phoneFromParams.slice(1)
          : phoneFromParams;
        console.log(phone);
        const currentUser = globalUsersList.notMyChats.find(
          (contact) => contact.UserDisplayPhoneNumber === phone,
        );
        if (currentUser) {
          openChatByContact(currentUser);
        } else {
          openChatByContact(globalUsersList.notMyChats[0]);
        }
      } else if (getParams.get("withPhone")) {
        console.log("With phone!");
        const paramsPhone = getParams.get("withPhone");
        const phone = phoneRegex.test(paramsPhone)
          ? "972" + paramsPhone.slice(1)
          : paramsPhone;
        console.log(phone);
        const currentUser = globalUsersList.notMyChats.find(
          (contact) => contact.UserDisplayPhoneNumber === phone,
        );
        if (currentUser) {
          openChatByContact(currentUser);
        } else {
          handleSetNotif(
            localizations["he"].chat.notifications.noUserByGetPhoneErr,
            "error",
          );
          openChatByContact(globalUsersList.notMyChats[0]);
        }
      } else if (isChatOpenLocalStorage?.userId) {
        const currentUser = globalUsersList.notMyChats.find(
          (contact) =>
            contact.UserDisplayPhoneNumber === isChatOpenLocalStorage.userId,
        );
        if (currentUser) {
          openChatByContact(currentUser);
        } else {
          openChatByContact(globalUsersList.notMyChats[0]);
        }
      } else {
        openChatByContact(globalUsersList.notMyChats[0]);
      }
    }

    async function loadFunnerChatUsers() {
      await getCompanyChatUsers();
      setContactsLoaded(true);
    }
  }
  async function bulkChatUserActions(action, value) {
    try {
      const params = {
        bulkActionName: action,
        chatUsersList: selectedChatUsersListRef.current.map(
          (user) => user.UserDisplayPhoneNumber,
        ),
      };
      // if (["openCloseConvStatus", "setLabel", "setRead"].includes(action)) {
      //   params.chatUsersList = selectedChatUsersListRef.current.map(
      //     (user) => user.UserDisplayPhoneNumber,
      //   );
      // }
      if (action === "setUnread") {
        const messagesList = selectedChatUsersListRef.current
          .filter((user) => user.incomingLastMessageId)
          .map((user) => Number(user.incomingLastMessageId));
        params.messageList = messagesList;
      } else if (action === "openCloseConvStatus") {
        params.isOpen = value;
      } else if (action === "setLabel") {
        params.labelName = JSON.stringify(value);
      } else if (action === "changeAssignee") {
        params.assigneeId = value;
      }

      const res = await UseFetchPost(
        "/api/services/bulkChatUserActions",
        params,
        "history",
      );

      console.log(res);
    } catch (error) {}
  }
  async function updateChatUserIcon(phoneNumber) {
    handleAdditionalMenuClose();
    if (!phoneNumber)
      return enqueueSnackbar("No phone number", { variant: "error" });
    try {
      const res = await UseFetchGet(
        "/api/services/getChatUserWhatsappUserPick",
        { whatsappPhoneNumberId: phoneNumber },
        null,
        false,
        "history",
      );

      if (res?.data?.result) {
        updateChatUser(phoneNumber, {
          UserPic: res.data.userPick,
        });
      } else {
        enqueueSnackbar(
          "תמונת הפרופיל של איש קשר זה אינה זמינה בשל הגדרות פרטיות ואבטחה של איש הקשר",
          {
            variant: "warning",
          },
        );
      }
    } catch (error) {
      console.error("updateChatUserIcon error: ", error);
    }
  }

  //! UseEffects
  useEffect(() => {
    funnerOnLoadActions();

    document.addEventListener("keydown", manageSelectedChatUsersWithKeys);

    return () => {
      document.removeEventListener("keydown", manageSelectedChatUsersWithKeys);
    };
  }, []);

  useEffect(() => {
    if (userInSettingsCreationState !== null) {
      getCompanyChatUsers();
    }
  }, [userInSettingsCreationState]);
  useEffect(() => {
    const unreadChats =
      userData?.IsShowAllChatUsers && !isMyUsersChats
        ? chatUsersFunnerRef.current.notMyChats
            .map((user, index) => {
              return { user: user, index: index };
            })
            .filter((userObj) => userObj.user.UnreadMessagesNumber > 0)
        : chatUsersFunnerRef.current.myChats
            .map((user, index) => {
              return { user: user, index: index };
            })
            .filter((userObj) => userObj.user.UnreadMessagesNumber > 0);
    const unreadChatsInDOM = unreadChats.map((chat) => {
      return {
        user: chat.user,
        index: chat.index + 1,
      };
    });
    setUnreadChatUsers(unreadChatsInDOM);
  }, [chatUsersFunner, isMyUsersChats]);
  useEffect(() => {
    updateUnreadChatsIndicator();
  }, [unreadChatUsers]);

  function onSocketReconnect(connectionId) {
    reconnectListenerSet = true;
    getCompanyChatUsers();
  }

  useEffect(() => {
    if (isConnection && !reconnectListenerSet) {
      connection.onreconnected(onSocketReconnect);
    }
  }, [isConnection]);

  useEffect(() => {
    const isChatOpen = openUnreadMessagesPopup;

    if (!isChatOpen) {
      localStorage.setItem(
        "globalFunnerMessangerOpenStatus",
        JSON.stringify({ isChatOpen: false, userId: null }),
      );

      if (
        document.documentElement.classList.contains("funner-chat-popup-open")
      ) {
        document.documentElement.classList.remove("funner-chat-popup-open");
      }
    } else {
      const isUserChoosed = Boolean(
        selectedFunnerChatUser?.UserDisplayPhoneNumber,
      );
      if (!isUserChoosed) {
        localStorage.setItem(
          "globalFunnerMessangerOpenStatus",
          JSON.stringify({ isChatOpen: true, user: null }),
        );
      } else {
        localStorage.setItem(
          "globalFunnerMessangerOpenStatus",
          JSON.stringify({
            isChatOpen: true,
            userId: selectedFunnerChatUser.UserDisplayPhoneNumber,
          }),
        );

        // console.log("textarea must focus: ", textareaRef.current);
        setTimeout(() => {
          if (textareaRef.current) textareaRef.current.focus();
        }, 0);
      }

      if (
        !document.documentElement.classList.contains("funner-chat-popup-open")
      ) {
        document.documentElement.classList.add("funner-chat-popup-open");
      }
    }
  }, [openUnreadMessagesPopup, selectedFunnerChatUser?.UserDisplayPhoneNumber]);

  return (
    <>
      <Dialog
        open={showUsersCreateChat}
        onClose={() => setShowUsersCreateChat(false)}
        fullWidth={true}
        maxWidth="md"
        className={
          applicationType === "extension"
            ? "_funner-global-messanger-container"
            : null
        }
      >
        <DialogContent sx={{ p: 0 }}>
          <Box
            className={applicationType === "extension" ? "root-whatsapp" : null}
          >
            <AddUsersChat
              setShowUsersCreateChat={setShowUsersCreateChat}
              handleSetNotif={handleSetNotif}
              // setUserCreationState={setUserCreationState}
              templatesList={templatesList}
              globalID={userData.Udid}
              fetchWhatsappTemplates={fetchWhatsappTemplates}
              openChatByContact={openChatByContact}
              getCompanyChatUsers={getCompanyChatUsers}
              getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <div
        className={`unread-messages-popup ${
          openUnreadMessagesPopup ? "visible" : ""
        } ${
          applicationType === "web"
            ? "funner-global-web-app root-whatsapp rtl"
            : ""
        } ${applicationType === "web" && isNavOpen ? "nav-open" : ""} ${
          iframeMode ? "funner-global-web-app--iframe" : ""
        }`}
      >
        <div
          className={`unread-messages-popup__content ${
            selectedFunnerChatUser ? "chat-open" : ""
          }`}
        >
          {!(iframeMode && iframeMode === "conversation") && (
            <Box
              style={{
                width:
                  applicationType === "extension" || matchesMD ? "auto" : 450,
                flex:
                  applicationType === "extension" || matchesMD
                    ? "0 0 auto"
                    : "0 0 450px",
              }}
            >
              <Box
                sx={{
                  maxHeight: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FunnerHeader
                  searchParams={getContactsQueryParams}
                  searchParamsRef={getContactsQueryParamsRef}
                  handleGetContactsParams={handleGetContactsParams}
                  getCompanyChatUsers={getCompanyChatUsers}
                  getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
                  updateUserConfig={updateUserConfig}
                  isMyUsersChats={isMyUsersChats}
                  setMyUsersChat={setMyUsersChat}
                  chatUsersLabelsList={chatUsersLabelsList}
                  userData={userData}
                  funnerUsers={funnerUsers}
                  setShowUsersCreateChat={setShowUsersCreateChat}
                  toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
                  selectedChatUsersList={selectedChatUsersList}
                  bulkChatUserActions={bulkChatUserActions}
                  templatesList={templatesList}
                  handleSetNotif={handleSetNotif}
                  fetchWhatsappTemplates={fetchWhatsappTemplates}
                  chatUsersGlobalRef={chatUsersGlobalRef}
                />
                <Box sx={{ flex: "1 1 auto", position: "relative" }}>
                  <List
                    component="nav"
                    ref={chatUsersListRef}
                    onScroll={handleLoadContactsOnScroll}
                    sx={{
                      position: "relative",
                      height:
                        applicationType === "extension"
                          ? "calc(70vh - 125px)"
                          : "calc(100svh - 157px)",
                      minHeight:
                        applicationType === "extension" ? "375px" : null,
                      overflow: "auto",
                      bgcolor: "common.white",
                      "&::-webkit-scrollbar": {
                        width: 10,
                        padding: 0,
                      },
                      "&::-webkit-scrollbar-track": {
                        padding: 0,
                        width: "100%",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderWidth: 1,
                      },
                    }}
                  >
                    <>
                      {(() => {
                        let usersToDisplay = [];

                        if (
                          businessInfo?.IsShowAllChatUsers ||
                          businessInfo?.IsShowAllChatUsers === null
                        ) {
                          usersToDisplay = isMyUsersChats
                            ? chatUsersFunner?.myChats
                            : chatUsersFunner?.notMyChats;
                        } else {
                          usersToDisplay = chatUsersFunner?.myChats;
                        }

                        return usersToDisplay?.length ? (
                          usersToDisplay.map((user) => (
                            <ChatUserCard
                              key={user.Id || user.UserDisplayPhoneNumber}
                              user={user}
                              selected={
                                selectedFunnerChatUser?.UserDisplayPhoneNumber ===
                                  user.UserDisplayPhoneNumber ||
                                selectedChatUsersList.some(
                                  (item) => item.Id === user.Id,
                                )
                              }
                              showAvatar={
                                userConfig.isAvatarActive === undefined ||
                                userConfig.isAvatarActive === null
                                  ? true
                                  : userConfig.isAvatarActive
                              }
                              handleAdditionalMenuClick={
                                handleAdditionalMenuClick
                              }
                              onUserCardClick={onUserCardClick}
                              funnerUsers={funnerUsers}
                              chatUsersCustomizationSettings={
                                chatUsersCustomizationSettings
                              }
                              chatUsersCustomizationAdditionalSettings={
                                chatUsersCustomizationAdditionalSettings
                              }
                              chatUsersLabelsList={chatUsersLabelsList}
                            />
                          ))
                        ) : isContactsLoaded ? (
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "500 !important",
                              my: 2,
                            }}
                          >
                            {localizations["he"].chat.chatUsers.noUsersFound}
                          </Typography>
                        ) : (
                          [...Array(15)].map((_, index) => {
                            return (
                              <Box key={index}>
                                <ListItemButton
                                  sx={{
                                    p: 1.5,
                                  }}
                                >
                                  {showAvatar && (
                                    <ListItemAvatar>
                                      <Skeleton
                                        variant="circular"
                                        sx={{
                                          width:
                                            applicationType === "extension" ||
                                            matchesBigMobile
                                              ? 40
                                              : 55,
                                          height:
                                            applicationType === "extension" ||
                                            matchesBigMobile
                                              ? 40
                                              : 55,
                                          mr:
                                            applicationType === "extension" ||
                                            matchesBigMobile
                                              ? 1
                                              : 1.5,
                                        }}
                                      />
                                    </ListItemAvatar>
                                  )}

                                  <ListItemText
                                    primary={
                                      <Grid
                                        container
                                        columnSpacing={2}
                                        rowSpacing={0}
                                        flexWrap={"nowrap"}
                                      >
                                        <Grid item flexGrow={1}>
                                          <Box sx={{ mb: 1 }}>
                                            <Grid
                                              container
                                              spacing={1.5}
                                              alignItems={"center"}
                                              flexWrap={"nowrap"}
                                            >
                                              <Grid item xs="auto">
                                                <Skeleton
                                                  variant="circular"
                                                  sx={{
                                                    width: 6,
                                                    height: 6,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs="auto">
                                                <Skeleton
                                                  variant="rounded"
                                                  sx={{
                                                    width:
                                                      applicationType ===
                                                        "extension" &&
                                                      showAvatar
                                                        ? 80
                                                        : 100,
                                                    height: 24,
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs="auto">
                                                <Skeleton
                                                  variant="rounded"
                                                  sx={{ width: 80, height: 24 }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Box>

                                          <Box>
                                            <Grid
                                              container
                                              columnSpacing={1}
                                              flexWrap={"nowrap"}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sx={{ mt: -0.375 }}
                                              >
                                                <Grid item flexGrow={1}>
                                                  <Skeleton
                                                    variant="square"
                                                    sx={{
                                                      maxWidth: "100%",
                                                      width: "100%",
                                                      height: 15,
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                        <Grid item xs="auto">
                                          <Box
                                            sx={{
                                              width: 120,
                                              [theme.breakpoints.down(480)]: {
                                                width: 100,
                                              },
                                            }}
                                          >
                                            <Grid
                                              container
                                              spacing={
                                                applicationType ===
                                                  "extension" ||
                                                matchesBigMobile
                                                  ? 0.5
                                                  : 1
                                              }
                                              direction={"column"}
                                            >
                                              <Grid item sx={{ mt: -0.25 }}>
                                                <Grid
                                                  container
                                                  spacing={0}
                                                  alignItems={"center"}
                                                  wrap="nowrap"
                                                >
                                                  <Grid item flexGrow={1}>
                                                    <Box
                                                      sx={{
                                                        width: 80,
                                                        [theme.breakpoints.down(
                                                          480,
                                                        )]: {
                                                          width: 28,
                                                          ml: "auto",
                                                          mr: 1,
                                                        },
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: 1,
                                                        }}
                                                      >
                                                        <Skeleton
                                                          variant="circular"
                                                          sx={{
                                                            width:
                                                              "18px !important",
                                                            height:
                                                              "18px !important",
                                                            fontSize:
                                                              "11px !important",

                                                            [theme.breakpoints.down(
                                                              480,
                                                            )]: {
                                                              width:
                                                                "28px !important",
                                                              height:
                                                                "28px !important",
                                                              fontSize:
                                                                "16px !important",
                                                            },
                                                          }}
                                                        />
                                                        {!matchesMobile && (
                                                          <Skeleton
                                                            variant="rounded"
                                                            height={20}
                                                            sx={{
                                                              flex: "1 1 auto",
                                                            }}
                                                          />
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  </Grid>
                                                  <Grid item></Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item>
                                                <Box sx={{ pr: 1 }}>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems={"center"}
                                                    justifyContent={"flex-end"}
                                                  >
                                                    <Grid item xs="auto">
                                                      <Skeleton
                                                        variant="rounded"
                                                        sx={{
                                                          width: 70,
                                                          height: 20,
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    }
                                    sx={{ my: 0 }}
                                  />
                                </ListItemButton>
                                <Divider sx={{ width: "100%" }} />
                              </Box>
                            );
                          })
                        );
                      })()}
                    </>
                  </List>
                  <Zoom in={unreadHint.enabled}>
                    <Button
                      onClick={scrollChatUsersTop}
                      size="small"
                      color="error"
                      variant="contained"
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: "50%",
                        transform: "translateX(50%)",
                        zIndex: 10,
                        width: 28,
                        minWidth: 28,
                        minHeight: 28,
                        height: 28,
                        borderRadius: "50%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: 0,
                          height: 0,
                          borderBottom: `6px solid ${theme.palette.error.main}`,
                          borderLeft: "4px solid transparent",
                          borderRight: "4px solid transparent",
                          top: 0,
                          left: 10,
                          transform: "translateY(-75%)",
                        }}
                      ></div>
                      {unreadHint.count}
                    </Button>
                  </Zoom>
                  <Zoom in={goToTopTrigger}>
                    <Fab
                      onClick={scrollChatUsersTop}
                      color="primary"
                      size="medium"
                      sx={{
                        position: "absolute",
                        bottom: 60,
                        left: 10,
                        zIndex: 10,
                      }}
                    >
                      <KeyboardArrowUp />
                    </Fab>
                  </Zoom>
                </Box>

                <Menu
                  anchorEl={additionalMenuAnchorEl.anchor}
                  open={Boolean(additionalMenuAnchorEl.anchor)}
                  onClose={handleAdditionalMenuClose}
                  disableScrollLock={true}
                >
                  <MenuItem
                    onClick={() =>
                      makeChatUserUnread(additionalMenuAnchorEl.chatUser)
                    }
                  >
                    {
                      localizations["he"].chat.chatUsers.userAdditionalMenu
                        .makeUnread
                    }
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      makeSingleChatUserRead(
                        additionalMenuAnchorEl.chatUser
                          ?.UserDisplayPhoneNumber || "",
                      )
                    }
                  >
                    {
                      localizations["he"].chat.chatUsers.userAdditionalMenu
                        .makeRead
                    }
                  </MenuItem>
                  {businessInfo?.CompanyType === "fireberry" && (
                    <MenuItem
                      onClick={() =>
                        openFireberrySyncUser(additionalMenuAnchorEl.chatUser)
                      }
                    >
                      {
                        localizations["he"].chat.chatUsers.userAdditionalMenu
                          .fireberrySync
                      }
                    </MenuItem>
                  )}
                  {businessInfo?.CompanyType === "priority" && (
                    <MenuItem
                      onClick={() =>
                        openPrioritySyncUser(additionalMenuAnchorEl.chatUser)
                      }
                    >
                      {
                        localizations["he"].chat.chatUsers.userAdditionalMenu
                          .prioritySync
                      }
                    </MenuItem>
                  )}
                  {showAvatar && (
                    <MenuItem
                      onClick={() =>
                        updateChatUserIcon(
                          additionalMenuAnchorEl.chatUser
                            ?.UserDisplayPhoneNumber,
                        )
                      }
                    >
                      Get user picture
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          )}

          <Chat
            chatType={"funner"}
            selectedUserId={selectedFunnerChatUser?.UserDisplayPhoneNumber}
            selectedChatUser={selectedFunnerChatUser}
            onUserCardClick={onUserCardClick}
            selectedUserRef={selectedFunnerChatUserRef}
            chatUsersFunnerRef={chatUsersFunnerRef}
            chatUsersGlobal={chatUsersGlobal}
            chatUsersGlobalRef={chatUsersGlobalRef}
            userData={userData}
            userDataRef={userDataRef}
            userConfig={userConfig}
            chatLoading={chatLoading}
            setChatLoading={setChatLoading}
            funnerUsers={funnerUsers}
            closeChat={closeChat}
            handleSetNotif={handleSetNotif}
            chatUsersLabelsList={chatUsersLabelsList}
            toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
            updateChatUser={updateChatUser}
            businessInfo={businessInfo}
            templatesList={templatesList}
            templatesLoading={templatesLoading}
            fetchWhatsappTemplates={fetchWhatsappTemplates}
            connection={connection}
            isConnection={isConnection}
            tabActiveRef={tabActiveRef}
            handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
            sendUploadPriority={sendUploadPriority}
            resetSendUploadPriority={resetSendUploadPriority}
            isNavOpen={isNavOpen}
            chatUsersMessagesRef={chatUsersMessagesRef}
            handleChatUserMessages={handleChatUserMessages}
            clearChatUsersMessages={clearChatUsersMessages}
            disableChatGoBack={disableChatGoBack}
            textareaRef={textareaRef}
            getChatMissingHistory={getChatMissingHistory}
            goToFavouriteMessageState={goToFavouriteMessageState}
            goToFavouriteMessageStateRef={goToFavouriteMessageStateRef}
            handleGoToFavouriteMessageState={handleGoToFavouriteMessageState}
          />
        </div>
      </div>
    </>
  );
}

export default FunnerGlobalMessanger;
