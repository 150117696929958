import React, { Fragment, useEffect, useRef, useState } from "react";
import { IsJsonString, isHebrewString } from "../../../libs/helpers";
import {
  UseFetchGet,
  UseFetchPost,
  UseFetchPostAnonymos,
  webAppDomain,
} from "../../../hooks/fetchFunctions";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowForwardIos,
  AutoAwesomeOutlined,
  Check,
  Close,
  Description,
  DriveFileRenameOutlineOutlined,
  EditOutlined,
  ExpandMore,
  FolderZip,
  ForumOutlined,
  Image,
  KeyboardArrowDown,
  Label,
  LabelOutlined,
  LocationOn,
  Mic,
  MoreVertOutlined,
  OpenInNew,
  Person,
  PowerSettingsNewOutlined,
  SearchOutlined,
  StickyNote2,
  SupportAgentOutlined,
  VideoCameraBack,
} from "@mui/icons-material";
import localizations from "../../../localizations.json";
import { applicationType } from "../../../applicationType";
import { FixedSizeList } from "react-window";
import { useSnackbar } from "notistack";
import { phoneGlobalRegex } from "../../../libs/regexes";

const fileTypes = ["pdf", "xlsx", "xls", "docx", "doc", "pdf"];

const HeaderSelectButton = styled(
  ({ matchesBigMobile, matchesMobile, ...props }) => (
    <Button
      variant={props.variant ? props.variant : "outlined"}
      color="info"
      size="small"
      sx={{
        py: applicationType === "extension" || matchesBigMobile ? 0.75 : 1,
        px: applicationType === "extension" || matchesBigMobile ? 0.75 : 1,
        borderRadius: 0.75,
        borderWidth: "2px !important",
        borderStyle: "solid",
        height: applicationType === "extension" || matchesBigMobile ? 36 : 42,
        minWidth: matchesMobile ? 36 : 42,
        fontSize:
          applicationType === "extension" || matchesBigMobile ? 12 : undefined,
        color: "white",
        borderColor: "rgba(238, 238, 238, 0.30)",
        "&:hover": {
          borderColor: "common.white",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        },
        ...(props.variant === "contained" && {
          color: "primary.main",
          backgroundColor: "common.white",
          "&:hover": {
            backgroundColor: "grey.100",
          },
        }),

        "& .MuiButton-startIcon": {
          mr: matchesMobile || applicationType === "extension" ? 0 : undefined,
        },
        "& .MuiButton-endIcon": {
          color: "white",
          ml: applicationType === "extension" ? 0.25 : undefined,
        },
      }}
      endIcon={
        !matchesMobile && (
          <ExpandMore
            sx={{
              fontSize: applicationType === "extension" ? 16 : undefined,
            }}
          />
        )
      }
      {...props}
    />
  ),
)(({ theme }) => ({}));

function ChatHeader({
  chatType,
  selectedChatUser,
  selectedUserId,
  handleChatUserConfig,
  chatLoading,
  selectedChatUserConfig,
  handleBotStatus,
  funnerUsers,
  closeChat,
  chatUsersLabelsList,
  toggleUnreadMessagesPopup,
  userData,
  userConfig,
  updateChatUser,
  isTemplatesAccordionOpen,
  isNavOpen,
  handleSearchInput,
  searchQuery,
  searchMessagesActive,
  setSearchMessagesActive,
  filteredSearchMessages,
  clearSearch,
  onSearchMessageClick,
  chatFolded,
  isHashtag,
  disableChatGoBack,
  handleConversationCasesAnchor,
  chatUserConversations,
}) {
  const theme = useTheme();
  const matchesBigDesktop = useMediaQuery(theme.breakpoints.down(1920));
  const matchesMinTemplatesMedia = useMediaQuery(theme.breakpoints.up(1536));
  const matchesMDDesktop = useMediaQuery(theme.breakpoints.down(1400));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
  const matchesMobile = useMediaQuery(theme.breakpoints.down(480));
  const localization = localizations["he"].chat.chatHeader;
  const { enqueueSnackbar } = useSnackbar();

  const [isNameEditable, setIsNameEditable] = useState(false);
  // const [labelAnchorEl, setLabelAnchorEl] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [anchorUserNoteEl, setAnchorUserNoteEl] = useState(null);
  const [anchorSupportTicketIdEl, setAnchorSupportTicketIdEl] = useState(null);
  const [botStatusAnchor, setBotStatusAnchor] = useState(null);
  const [botStatusObj, setBotStatusObj] = useState(null);
  const [isAvatarDialog, setIsAvatarDialog] = useState(false);
  const [isChatWindowSmall, setIsChatWindowSmall] = useState(false);
  const [searchPopoverAnchor, setSearchPopoverAnchor] = useState(false);

  const headerElementRef = useRef(null);
  const isChatWindowSmallRef = useRef(false);
  const threeDotsBtnRef = useRef(null);
  const anchorUserNoteInputRef = useRef(null);
  const anchorSupportTicketIdInputRef = useRef(null);

  let chatUserSelectedLabel =
    selectedChatUser &&
    selectedChatUser.LableName &&
    IsJsonString(selectedChatUser.LableName) &&
    JSON.parse(selectedChatUser.LableName)?.name
      ? JSON.parse(selectedChatUser.LableName)
      : {
          name: localization.filterLabel.noLabel,
          color: "rgb(42, 56, 71)",
        };
  let isUserHaveDeletedLabel = !chatUsersLabelsList?.some(
    (label) => label.name === chatUserSelectedLabel.name,
  );
  if (isUserHaveDeletedLabel) {
    chatUserSelectedLabel = {
      name: localization.filterLabel.noLabel,
      color: "rgb(42, 56, 71)",
    };
  }

  const selectedAssigneeName = selectedChatUser
    ? selectedChatUser.NotificationContactName
    : null;

  const nameRef = useRef(null);
  const searchInputRef = useRef(null);

  const handleNameEditable = (state) => {
    setIsNameEditable(state);
  };

  const handleMenuEl = (e, menu) => {
    setAnchorMenuEl(e.currentTarget);
    setMenuType(menu);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
    setTimeout(() => {
      setMenuType(null);
    }, 300);
  };
  const handleBotStatusEl = (e) => {
    setBotStatusAnchor(e.currentTarget);
    getBotStatus(selectedChatUser.UserDisplayPhoneNumber);
  };
  const handleBotStatusClose = () => {
    setBotStatusAnchor(null);
    setTimeout(() => {
      setBotStatusObj(null);
    }, 300);
  };

  const handlePopover = (e) => {
    setAnchorUserNoteEl(e.currentTarget);
    setTimeout(() => {
      anchorUserNoteInputRef.current?.focus();
    }, 100);
  };
  const handleSupportTicketIdEl = (el) => {
    setAnchorSupportTicketIdEl(el);

    setTimeout(() => {
      anchorSupportTicketIdInputRef.current?.focus();
    }, 100);
  };
  const handleClosePopover = () => {
    setAnchorUserNoteEl(null);
  };
  const handleCloseSupportTicket = () => {
    setAnchorSupportTicketIdEl(null);
  };

  function onUserChangedActions() {
    setAnchorUserNoteEl(null);
    setAnchorSupportTicketIdEl(null);
  }

  const submitUserNoteOnEnterKey = (e, source) => {
    if (e.keyCode === 13) {
      handleChatUserConfig(
        selectedChatUser.UserDisplayPhoneNumber,
        undefined,
        true,
        true,
      );
      if (source === "userNote") {
        handleClosePopover();
      } else if (source === "supportTicketId") {
        handleSupportTicketIdEl(null);
      }
    }
  };

  const showAvatarDialog = () => {
    setIsAvatarDialog(true);
  };
  const closeAvatarDialog = () => {
    setIsAvatarDialog(false);
  };

  async function setNewUserName() {
    const formData = {
      phone: selectedChatUser.UserDisplayPhoneNumber,
      newName: nameRef.current.textContent,
    };

    try {
      const response = await UseFetchPostAnonymos(
        "/api/services/UpdateChatUserName",
        formData,
        "config",
      );

      if (response.data.result) {
        updateChatUser(selectedChatUser.UserDisplayPhoneNumber, {
          FullName: nameRef.current.textContent,
        });
      } else {
        console.log("error editing name");
      }
    } catch (error) {
      console.log("Something went wrong editing name:", error);
    }

    handleNameEditable(false);
  }
  async function handleSetSingleUserLabel(option) {
    handleMenuClose();
    try {
      const response = await UseFetchPost(
        "/api/services/SetChatUserLabel",
        {
          id: selectedChatUser.UserDisplayPhoneNumber,
          labelName: JSON.stringify(option),
        },
        "history",
      );

      if (response?.data?.result) {
        updateChatUser(selectedChatUser.UserDisplayPhoneNumber, {
          LableName: JSON.stringify(option),
        });
      }
    } catch (error) {
      console.log("SetChatUserLabel error: ", error);
    }
  }
  async function handleStatusItemClick(state) {
    handleMenuClose();
    const params = {
      userglobalid: userData.Udid,
      chatUserDisplayPhoneNumber: selectedChatUser.UserDisplayPhoneNumber,
      isOpen: state,
    };

    try {
      UseFetchPost(`/api/services/SetChatUserOpenStatus`, params, "history");
    } catch (error) {
      console.error(error);
    }
  }
  async function handleOptionSelection(option) {
    handleMenuClose();
    try {
      const whatsappPhoneNumber = selectedChatUser.UserDisplayPhoneNumber;

      const res = await UseFetchPost(
        "/api/services/SetUserChatNotificationContact",
        {
          userglobalid: option ? option.Udid : null,
          displayPhoneNumber: whatsappPhoneNumber,
          companyId: userData.Udid,
        },
        "history",
      );

      if (res?.data?.result) {
        updateChatUser(whatsappPhoneNumber, {
          NotificationContactName: option ? option.FullName : null,
          NotificationContactUser: option ? option.Udid : null,
        });
      }
    } catch (error) {
      console.log("SetUserChatNotificationContact error: ", error);
    }
  }

  async function getBotStatus(phone) {
    try {
      const res = await UseFetchGet(
        "/api/services/getChatUserBotStatus",
        {
          chatUserPhoneId: phone,
        },
        null,
        undefined,
        "history",
      );
      console.log(res);
      if (res?.status === 200) {
        setBotStatusObj({ ...res.data });
      }
    } catch (error) {
      console.log("getChatUserBotStatus error: ", error);
    }
  }
  async function handleStopBot(botId) {
    try {
      const res = await UseFetchPost(
        "/api/services/stopUserBots",
        {
          userPhoneNumberId: selectedChatUser.UserDisplayPhoneNumber,
          botId: botId,
        },
        "history",
      );

      if (res?.data?.result) {
        enqueueSnackbar(
          `${localization.chatWindow.botStatus.stopBotNotif}: ${botId}`,
          "success",
        );
        setBotStatusObj((prevObj) => ({
          ...prevObj,
          ActiveBots: prevObj.ActiveBots.filter((bot) => bot.Id !== botId),
        }));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  //! UseEffects
  useEffect(() => {
    if (chatType === "fireberry") {
      const element = headerElementRef.current;
      if (!element) return;

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          let width;

          if (entry.borderBoxSize && entry.borderBoxSize.length > 0) {
            width = entry.borderBoxSize[0].inlineSize;
          } else {
            width = entry.contentRect.width;
          }
          if (width <= 385 && !isChatWindowSmallRef.current) {
            setIsChatWindowSmall(true);
            isChatWindowSmallRef.current = true;
          } else if (width > 385 && isChatWindowSmallRef.current) {
            setIsChatWindowSmall(false);
            isChatWindowSmallRef.current = false;
          }
        }
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNameEditable) {
      nameRef.current.focus();
    }
  }, [isNameEditable]);

  useEffect(() => {
    onUserChangedActions();
  }, [selectedChatUser?.UserDisplayPhoneNumber]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "primary.main",
            height:
              applicationType === "extension" || matchesBigMobile ? 65 : 72,
            // height: 56,
            display: "flex",
            alignItems: "center",
            px: applicationType === "extension" || matchesBigMobile ? 1 : 1.5,

            [theme.breakpoints.down(768)]: {
              pl: 7,
            },
          }}
          ref={headerElementRef}
        >
          <Grid
            container
            spacing={
              applicationType === "extension" ? 1 : matchesBigMobile ? 1.5 : 3
            }
            justifyContent={"space-between"}
            alignItems={"center"}
            wrap="nowrap"
          >
            <Grid item>
              <Grid
                container
                spacing={
                  applicationType === "extension" || matchesBigMobile ? 1 : 1.5
                }
                alignItems={"center"}
                wrap="nowrap"
              >
                {(applicationType === "extension" || matchesMD) &&
                  chatType !== "fireberry" &&
                  !disableChatGoBack && (
                    <Grid item>
                      <IconButton
                        size="small"
                        color="customWhite"
                        onClick={closeChat}
                      >
                        <ArrowForwardIos sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Grid>
                  )}

                <Grid item>
                  {(userConfig.isAvatarActive === undefined ||
                  userConfig.isAvatarActive === null
                    ? true
                    : userConfig.isAvatarActive) && (
                    <Avatar
                      sx={{
                        width:
                          applicationType === "extension" || matchesBigMobile
                            ? 30
                            : 36,
                        height:
                          applicationType === "extension" || matchesBigMobile
                            ? 30
                            : 36,
                        cursor: selectedChatUser?.UserPic
                          ? "pointer"
                          : "default",
                      }}
                      src={selectedChatUser?.UserPic}
                      onClick={() =>
                        selectedChatUser?.UserPic ? showAvatarDialog() : null
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={0.5}
                    alignItems={"center"}
                    sx={{
                      mb: -0.75,
                      "&:hover .edit-funner-name": {
                        opacity: 1,
                        visibility: "visible",
                        pointerEvents: "initial",
                      },
                    }}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Tooltip
                        disableFocusListener={
                          applicationType === "web" && !matchesBigMobile
                        }
                        disableHoverListener={
                          applicationType === "web" && !matchesBigMobile
                        }
                        disableInteractive={
                          applicationType === "web" && !matchesBigMobile
                        }
                        disableTouchListener={
                          applicationType === "web" && !matchesBigMobile
                        }
                        title={selectedChatUser?.FullName}
                      >
                        <Typography
                          variant={
                            applicationType === "extension" || matchesBigMobile
                              ? "body2"
                              : "subtitle1"
                          }
                          sx={{
                            color: theme.palette.common.white,
                            whiteSpace: "nowrap",
                            overflow:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? "hidden"
                                : undefined,
                            textOverflow: "ellipsis",
                            maxWidth:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? (
                                    userConfig.isAvatarActive === undefined ||
                                    userConfig.isAvatarActive === null
                                      ? true
                                      : userConfig.isAvatarActive
                                  )
                                  ? 60
                                  : 85
                                : undefined,
                            direction: !isHebrewString(
                              selectedChatUser?.FullName,
                            )
                              ? "rtl"
                              : "ltr",
                          }}
                          ref={nameRef}
                          contentEditable={isNameEditable}
                          onBlur={setNewUserName}
                        >
                          {selectedChatUser?.FullName}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        opacity: 0,
                        visibility: "hidden",
                        pointerEvents: "none",
                        transition:
                          "opacity 0.15s ease 0s, visibility 0.15s ease 0s",
                      }}
                      className="edit-funner-name"
                    >
                      <IconButton
                        sx={{
                          color: "white",
                          "&:hover": {
                            bgcolor: "rgba(255, 255, 255, 0.25)",
                          },
                          "& .MuiTouchRipple-child": {
                            backgroundColor: "white",
                          },
                        }}
                        size="small"
                        onClick={() => handleNameEditable(true)}
                      >
                        <EditOutlined sx={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.common.white }}
                  >
                    {(() => {
                      const phone = selectedChatUser?.UserDisplayPhoneNumber || null;
                      if (phone) {
                        const clearPhone = selectedChatUser.UserDisplayPhoneNumber.replaceAll(/\D/g, "");
                        const displayPhone = phoneGlobalRegex.test(clearPhone) ? "0" +
                        clearPhone.slice(3) : clearPhone;
                        return displayPhone;
                      } else return "חסר מספר טלפון";
                    })()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={
                  applicationType === "extension"
                    ? 0.75
                    : matchesBigMobile
                    ? 1
                    : 1.5
                }
                alignItems={"center"}
                wrap="nowrap"
              >
                {!isChatWindowSmall && (
                  <>
                    <Grid item>
                      <HeaderSelectButton
                        startIcon={
                          chatUserSelectedLabel &&
                          chatUserSelectedLabel.name !==
                            localization.filterLabel.noLabel ? (
                            <Label
                              sx={{
                                color: chatUserSelectedLabel.color,
                                fontSize:
                                  applicationType === "extension" ||
                                  matchesBigMobile
                                    ? 18
                                    : undefined,
                              }}
                            />
                          ) : matchesBigMobile ||
                            applicationType === "extension" ? (
                            <LabelOutlined
                              sx={{
                                color: "common.white",
                                fontSize:
                                  applicationType === "extension" ||
                                  matchesBigMobile
                                    ? 18
                                    : undefined,
                              }}
                            />
                          ) : null
                        }
                        onClick={(e) => handleMenuEl(e, "label")}
                        matchesBigMobile={matchesBigMobile}
                        matchesMobile={matchesMobile}
                      >
                        {applicationType !== "extension" &&
                          !matchesBigMobile && (
                            <Box
                              component={"span"}
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth:
                                  applicationType === "extension" ? 40 : 80,
                              }}
                            >
                              {chatUserSelectedLabel.name &&
                              chatUserSelectedLabel.name !==
                                localization.filterLabel.noFilter
                                ? chatUserSelectedLabel.name
                                : localization.chatWindow.labelSelect.noLabel}
                            </Box>
                          )}
                      </HeaderSelectButton>
                    </Grid>
                    <Grid item>
                      <HeaderSelectButton
                        onClick={(e) => handleMenuEl(e, "openStatus")}
                        matchesBigMobile={matchesBigMobile}
                        matchesMobile={matchesMobile}
                      >
                        <PowerSettingsNewOutlined
                          sx={{
                            color: selectedChatUser?.IsOpen
                              ? "#00C667"
                              : "error.light",
                            fontSize:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? 18
                                : undefined,
                          }}
                        />
                      </HeaderSelectButton>
                    </Grid>
                    <Grid item>
                      <HeaderSelectButton
                        onClick={(e) => handleBotStatusEl(e)}
                        matchesBigMobile={matchesBigMobile}
                        matchesMobile={matchesMobile}
                      >
                        <AutoAwesomeOutlined
                          sx={{
                            color: selectedChatUserConfig?.isBotActive
                              ? "#02FF0C"
                              : "#F44336",
                            fontSize:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? 18
                                : undefined,
                          }}
                        />
                      </HeaderSelectButton>
                    </Grid>
                  </>
                )}
                {applicationType === "extension" && (
                  <Grid item>
                    <IconButton
                      size="small"
                      color="customWhite"
                      sx={{ mr: -0.5 }}
                      href={`${webAppDomain}/?withPhone=${
                        selectedChatUser?.UserDisplayPhoneNumber || ""
                      }`}
                      target="_blank"
                      // onClick={(e) => handleBotStatusEl(e)}
                    >
                      <OpenInNew
                        sx={{
                          // color: "common.white",
                          fontSize: 20,
                        }}
                      />
                    </IconButton>
                  </Grid>
                )}
                {isChatWindowSmall && (
                  <Grid item>
                    <IconButton
                      color="customWhite"
                      size="small"
                      sx={{ mr: -0.5 }}
                      onClick={(e) => handleMenuEl(e, "chatSmallAdditional")}
                      ref={threeDotsBtnRef}
                    >
                      <MoreVertOutlined sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Grid>
                )}
                {applicationType === "extension" && (
                  <Grid item>
                    <IconButton
                      size="small"
                      color="customWhite"
                      onClick={toggleUnreadMessagesPopup}
                    >
                      {chatType === "fireberry" && chatFolded && isHashtag ? (
                        <KeyboardArrowDown
                          sx={{ color: "common.white", fontSize: 20 }}
                        />
                      ) : (
                        <Close sx={{ fontSize: 20 }} />
                      )}
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "grey.200",
            height:
              applicationType === "extension" || matchesBigMobile ? 60 : 80,
            // height: 54,
            display: "flex",
            alignItems: "center",
            px: applicationType === "extension" || matchesBigMobile ? 1 : 1.5,
          }}
        >
          <Grid
            container
            spacing={
              applicationType === "extension" || matchesBigMobile ? 1.5 : 3
            }
            alignItems={"center"}
            justifyContent={"space-between"}
            wrap={matchesMD ? "wrap" : "nowrap"}
          >
            <Grid item flexShrink={1}>
              <Grid
                container
                spacing={1.5}
                alignItems={"center"}
                flexWrap={"nowrap"}
              >
                <Grid item xs="auto">
                  {Boolean(anchorUserNoteEl) &&
                  !(matchesMDDesktop && isNavOpen) &&
                  !matchesLG &&
                  applicationType !== "extension" ? (
                    <TextField
                      // label={localization.chatWindow.userNote.inputLabel}
                      variant="standard"
                      size="small"
                      sx={{ width: 180 }}
                      value={selectedChatUserConfig?.userNote || ""}
                      onChange={(e) =>
                        handleChatUserConfig(
                          selectedChatUser.UserDisplayPhoneNumber,
                          { userNote: e.target.value },
                          false,
                          false,
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleChatUserConfig(
                                  selectedChatUser.UserDisplayPhoneNumber,
                                  undefined,
                                  true,
                                  true,
                                );
                                handleClosePopover();
                              }}
                              size="small"
                            >
                              <Check sx={{ color: "success.main" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          fontSize: 14,
                        },
                      }}
                      onKeyDown={(e) => submitUserNoteOnEnterKey(e, "userNote")}
                      inputRef={anchorUserNoteInputRef}
                    />
                  ) : (
                    <Tooltip title={localization.chatWindow.userNote.labelText}>
                      <Button
                        variant="standard"
                        startIcon={
                          <DriveFileRenameOutlineOutlined
                            sx={{
                              color: "action.active",
                            }}
                          />
                        }
                        sx={{
                          minWidth: 0,
                          "& .MuiButton-startIcon": {
                            marginRight:
                              (matchesMDDesktop && isNavOpen) || matchesMobile
                                ? 0
                                : undefined,
                          },
                        }}
                        onClick={handlePopover}
                      >
                        {((!(matchesMDDesktop && isNavOpen) &&
                          !matchesMobile &&
                          !(
                            matchesMinTemplatesMedia && isTemplatesAccordionOpen
                          ) &&
                          applicationType === "web") ||
                          (applicationType === "extension" && !matchesLG)) && (
                          <Box
                            component={"span"}
                            sx={{
                              color: "primary.main",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize:
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? 12
                                  : undefined,
                              width:
                                applicationType === "extension" ? "100%" : 120,
                              maxWidth:
                                applicationType === "extension" ? 60 : null,
                              textAlign: "left",
                            }}
                          >
                            {selectedChatUserConfig?.userNote
                              ? selectedChatUserConfig.userNote
                              : localization.chatWindow.userNote.labelText}
                          </Box>
                        )}
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs="auto">
                  {Boolean(anchorSupportTicketIdEl) &&
                  !(matchesMDDesktop && isNavOpen) &&
                  !matchesLG &&
                  applicationType !== "extension" ? (
                    <TextField
                      // label={localization.chatWindow.supportTicketId.inputLabel}
                      variant="standard"
                      sx={{ width: 180 }}
                      size="small"
                      value={selectedChatUserConfig?.supportTicketId || ""}
                      onChange={(e) =>
                        handleChatUserConfig(
                          selectedChatUser.UserDisplayPhoneNumber,
                          { supportTicketId: e.target.value },
                          false,
                          false,
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleChatUserConfig(
                                  selectedChatUser.UserDisplayPhoneNumber,
                                  undefined,
                                  true,
                                  true,
                                );
                                handleSupportTicketIdEl(null);
                              }}
                              size="small"
                            >
                              <Check sx={{ color: "success.main" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          fontSize: 14,
                        },
                      }}
                      onKeyDown={(e) =>
                        submitUserNoteOnEnterKey(e, "supportTicketId")
                      }
                      inputRef={anchorSupportTicketIdInputRef}
                    />
                  ) : (
                    <Tooltip
                      title={localization.chatWindow.supportTicketId.labelText}
                    >
                      <Button
                        variant="standard"
                        startIcon={
                          <SupportAgentOutlined
                            sx={{
                              color: "action.active",
                            }}
                          />
                        }
                        sx={{
                          minWidth: 0,
                          "& .MuiButton-startIcon": {
                            marginRight:
                              (matchesMDDesktop && isNavOpen) || matchesMobile
                                ? 0
                                : undefined,
                          },
                        }}
                        onClick={(e) =>
                          handleSupportTicketIdEl(e.currentTarget)
                        }
                      >
                        {((!(matchesMDDesktop && isNavOpen) &&
                          !matchesMobile &&
                          !(
                            matchesMinTemplatesMedia && isTemplatesAccordionOpen
                          ) &&
                          applicationType === "web") ||
                          (applicationType === "extension" && !matchesLG)) && (
                          <Box
                            component={"span"}
                            sx={{
                              color: "primary.main",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize:
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? 12
                                  : undefined,
                              width:
                                applicationType === "extension" ? "100%" : 120,
                              maxWidth:
                                applicationType === "extension" ? 60 : null,
                              textAlign: "left",
                            }}
                          >
                            {selectedChatUserConfig?.supportTicketId
                              ? selectedChatUserConfig.supportTicketId
                              : chatUserConversations?.[0]
                                  ?.chatSerialSubjectNumber ||
                                localization.chatWindow.supportTicketId
                                  .labelText}
                          </Box>
                        )}
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={localization.chatWindow.conversations.buttonTooltip}
                    placement="top"
                  >
                    <IconButton onClick={handleConversationCasesAnchor}>
                      <ForumOutlined />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {((matchesMDDesktop && isNavOpen) ||
                matchesLG ||
                applicationType === "extension") && (
                <Fragment>
                  <Popover
                    open={Boolean(anchorUserNoteEl)}
                    anchorEl={anchorUserNoteEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    slotProps={{
                      paper: {
                        sx: { maxWidth: 400 },
                      },
                    }}
                  >
                    <Box sx={{ px: 2, py: 1.25 }}>
                      <Grid container direction={"column"} spacing={1}>
                        <Grid item>
                          <TextField
                            value={selectedChatUserConfig?.userNote || ""}
                            size="small"
                            onChange={(e) =>
                              handleChatUserConfig(
                                selectedChatUser.UserDisplayPhoneNumber,
                                { userNote: e.target.value },
                                false,
                                false,
                              )
                            }
                            sx={{ width: 250 }}
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              handleChatUserConfig(
                                selectedChatUser.UserDisplayPhoneNumber,
                                undefined,
                                true,
                                true,
                              );
                            }}
                          >
                            <Check sx={{ color: "common.white" }} />
                            {/* {
                              localization.chatWindow.userNote.popoverContent
                                .submit
                            } */}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                  <Popover
                    open={Boolean(anchorSupportTicketIdEl)}
                    anchorEl={anchorSupportTicketIdEl}
                    onClose={handleCloseSupportTicket}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    slotProps={{
                      paper: {
                        sx: { maxWidth: 400 },
                      },
                    }}
                  >
                    <Box sx={{ px: 2, py: 1.25 }}>
                      <Grid container direction={"column"} spacing={1}>
                        <Grid item>
                          <TextField
                            value={
                              selectedChatUserConfig?.supportTicketId || ""
                            }
                            size="small"
                            onChange={(e) =>
                              handleChatUserConfig(
                                selectedChatUser.UserDisplayPhoneNumber,
                                { supportTicketId: e.target.value },
                                false,
                                false,
                              )
                            }
                            sx={{ width: 250 }}
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              handleChatUserConfig(
                                selectedChatUser.UserDisplayPhoneNumber,
                                undefined,
                                true,
                                true,
                              );
                            }}
                          >
                            <Check sx={{ color: "common.white" }} />
                            {/* {
                              localization.chatWindow.userNote.popoverContent
                                .submit
                            } */}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </Fragment>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} wrap="nowrap" spacing={2}>
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      color: "#7C7C7C",
                      py: 0.75,
                      whiteSpace: "nowrap",
                    }}
                    startIcon={
                      selectedAssigneeName ? (
                        <Avatar
                          alt={selectedAssigneeName}
                          color="grey.400"
                          sx={{
                            width: "24px !important",
                            height: "24px !important",
                            fontSize: "12px !important",
                          }}
                        >
                          {selectedAssigneeName.split(" ").length > 0 &&
                            `${selectedAssigneeName.split(" ")[0].slice(0, 1)}${
                              selectedAssigneeName.split(" ")[1]
                                ? selectedAssigneeName.split(" ")[1].slice(0, 1)
                                : ""
                            }`}
                        </Avatar>
                      ) : null
                    }
                    endIcon={<ExpandMore sx={{ color: "text.disabled" }} />}
                    onClick={(e) => handleMenuEl(e, "assigneeSelect")}
                  >
                    <Tooltip
                      disableFocusListener={applicationType === "web"}
                      disableHoverListener={applicationType === "web"}
                      disableInteractive={applicationType === "web"}
                      disableTouchListener={applicationType === "web"}
                      title={
                        selectedAssigneeName
                          ? selectedAssigneeName
                          : localization.chatWindow.assigneeSelect.noSelected
                      }
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth:
                            applicationType === "extension"
                              ? 80
                              : matchesBigMobile
                              ? 100
                              : 120,
                        }}
                      >
                        {selectedAssigneeName
                          ? selectedAssigneeName
                          : localization.chatWindow.assigneeSelect.noSelected}
                      </Box>
                    </Tooltip>
                  </Button>
                </Grid>
                {applicationType !== "extension" &&
                  !matchesLG &&
                  !(
                    matchesBigDesktop &&
                    isTemplatesAccordionOpen &&
                    matchesMinTemplatesMedia
                  ) && (
                    <Grid item flexGrow={1}>
                      <Box
                        sx={{
                          width: isTemplatesAccordionOpen ? 240 : 326,
                          [theme.breakpoints.down("xl")]: {
                            width: 200,
                          },
                        }}
                      >
                        <TextField
                          placeholder={
                            localization.chatWindow.searchMessages
                              .inputPlaceholder
                          }
                          onChange={handleSearchInput}
                          value={searchQuery}
                          fullWidth
                          size="small"
                          ref={searchInputRef}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlined />
                              </InputAdornment>
                            ),
                            endAdornment: searchMessagesActive && (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  sx={{ width: 30, height: 30 }}
                                  onClick={clearSearch}
                                >
                                  <Close sx={{ color: "error.light" }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            sx: {
                              fontSize: 14,
                            },
                          }}
                          sx={{ bgcolor: "common.white" }}
                        />
                      </Box>
                    </Grid>
                  )}
                {((matchesBigDesktop &&
                  isTemplatesAccordionOpen &&
                  matchesMinTemplatesMedia) ||
                  applicationType === "extension") && (
                  <Grid item>
                    <IconButton
                      onClick={(e) => setSearchPopoverAnchor(e.currentTarget)}
                    >
                      <SearchOutlined />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Menu
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={handleMenuClose}
        sx={{ mt: 0.75, maxHeight: 400 }}
        disableScrollLock={true}
        dir="rtl"
      >
        {menuType === "chatSmallAdditional" && [
          <MenuItem
            key="labelsAdditional"
            sx={{ gap: 2 }}
            onClick={() => setMenuType("label")}
          >
            {chatUserSelectedLabel &&
            chatUserSelectedLabel.name !== localization.filterLabel.noLabel ? (
              <Label
                sx={{
                  color: chatUserSelectedLabel.color,
                }}
              />
            ) : (
              <LabelOutlined
                sx={{
                  color: "text.secondary",
                }}
              />
            )}
            {/* <Typography>
              {chatUserSelectedLabel.name &&
              chatUserSelectedLabel.name !== localization.filterLabel.noFilter
                ? chatUserSelectedLabel.name
                : localization.chatWindow.labelSelect.noLabel}
            </Typography> */}
          </MenuItem>,
          <MenuItem
            key="isChatOpenAdditional"
            onClick={() => setMenuType("openStatus")}
          >
            <PowerSettingsNewOutlined
              sx={{
                color: selectedChatUser?.IsOpen ? "#00C667" : "error.light",
              }}
            />
          </MenuItem>,
          <MenuItem
            key="BotStatusAdditional"
            onClick={(e) => {
              handleMenuClose();
              handleBotStatusEl({
                currentTarget: threeDotsBtnRef.current,
              });
            }}
          >
            <AutoAwesomeOutlined
              sx={{
                color: selectedChatUserConfig?.isBotActive
                  ? "#02FF0C"
                  : "#F44336",
              }}
            />
          </MenuItem>,
        ]}
        {menuType === "label" &&
          chatUsersLabelsList.map((label) => (
            <MenuItem
              key={label.name}
              sx={{ color: "primary.main" }}
              onClick={() => handleSetSingleUserLabel(label)}
            >
              <Label sx={{ color: label.color, mr: 1.5 }} />
              {label.name}
            </MenuItem>
          ))}
        {menuType === "openStatus" && (
          <MenuItem
            sx={{ color: "primary.main" }}
            onClick={() =>
              handleStatusItemClick(selectedChatUser?.IsOpen ? false : true)
            }
          >
            {selectedChatUser?.IsOpen
              ? localization.chatWindow.openStatusSelect.makeClose
              : localization.chatWindow.openStatusSelect.makeOpen}
          </MenuItem>
        )}
        {menuType === "assigneeSelect" && [
          <MenuItem
            onClick={() => handleOptionSelection(null)}
            key={"chat-header-assignee-null"}
          >
            {localization.chatWindow.assigneeSelect.removeSelection}
          </MenuItem>,
          funnerUsers
            .filter((user) => {
              const userProfileConfig = IsJsonString(user.ProfileConfig)
                ? JSON.parse(user.ProfileConfig)
                : {};

              const visible =
                userProfileConfig?.funnerInfo?.visibleInList !== undefined &&
                userProfileConfig?.funnerInfo?.visibleInList !== null
                  ? userProfileConfig.funnerInfo.visibleInList
                  : user.IsActive;

              return visible;
            })
            .map((user) => (
              <MenuItem
                key={user.Udid}
                onClick={() => handleOptionSelection(user)}
              >
                <Avatar
                  alt={`${user.FirstName || ""} ${user.LastName || ""}`}
                  color="grey.400"
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    fontSize: "12px !important",
                    mr: 1.5,
                  }}
                >
                  {`${user.FirstName || ""} ${user.LastName || ""}`.split(" ")
                    .length > 0 &&
                    `${`${user.FirstName || ""} ${user.LastName || ""}`
                      .split(" ")[0]
                      .slice(0, 1)}${
                      `${user.FirstName || ""} ${user.LastName || ""}`.split(
                        " ",
                      )[1]
                        ? `${user.FirstName || ""} ${user.LastName || ""}`
                            .split(" ")[1]
                            .slice(0, 1)
                        : ""
                    }`}
                </Avatar>
                {`${user.FirstName || ""} ${user.LastName || ""}`}
              </MenuItem>
            )),
        ]}
      </Menu>

      <Popover
        open={Boolean(searchPopoverAnchor)}
        anchorEl={searchPopoverAnchor}
        onClose={() => {
          setSearchMessagesActive(false);
          setSearchPopoverAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <Box sx={{ p: 1, width: 300 }}>
          <TextField
            placeholder={
              localization.chatWindow.searchMessages.inputPlaceholder
            }
            onChange={handleSearchInput}
            value={searchQuery}
            fullWidth
            size="small"
            ref={searchInputRef}
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <SearchOutlined />
              //   </InputAdornment>
              // ),
              endAdornment: searchMessagesActive && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    sx={{ width: 30, height: 30 }}
                    onClick={clearSearch}
                  >
                    <Close sx={{ color: "error.light" }} />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                fontSize: 14,
              },
            }}
            sx={{ bgcolor: "common.white" }}
          />
        </Box>
      </Popover>

      <Popover
        open={Boolean(botStatusAnchor)}
        anchorEl={botStatusAnchor}
        onClose={handleBotStatusClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <Box
          sx={{
            p: 1,
            overflow: "auto",
            maxWidth: "100%",
          }}
        >
          <Box sx={{ width: applicationType === "extension" ? 250 : 400 }}>
            {botStatusObj ? (
              <Grid container spacing={2}>
                {botStatusObj.StatuDescription && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      {botStatusObj.StatuDescription}
                    </Typography>
                  </Grid>
                )}

                {botStatusObj.ActiveBots?.length > 0 ? (
                  <Fragment>
                    <Grid item xs={12}>
                      <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            {/* <Grid item xs={2}> */}
                            <Grid item xs={3}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  p: 0.5,
                                }}
                              >
                                {
                                  localization.chatWindow.botStatus.botsTable
                                    .header.botName
                                }
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={3.5}> */}
                            <Grid item xs={4}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  p: 0.5,
                                }}
                              >
                                {
                                  localization.chatWindow.botStatus.botsTable
                                    .header.completedStepName
                                }
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={4.5}> */}
                            <Grid item xs={5}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  p: 0.5,
                                }}
                              >
                                {
                                  localization.chatWindow.botStatus.botsTable
                                    .header.savedData
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {botStatusObj.ActiveBots.map((bot) => (
                          <Grid item xs={12} key={bot.Id}>
                            <Grid container spacing={1}>
                              {/* <Grid item xs={2}> */}
                              <Grid item xs={3}>
                                <Tooltip title={bot.FlowTemplateId}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%",
                                      p: 0.5,
                                    }}
                                  >
                                    {bot.FlowTemplateName}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              {/* <Grid item xs={3.5}> */}
                              <Grid item xs={4}>
                                <Tooltip title={bot.CompletedWorkflowId}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%",
                                      p: 0.5,
                                    }}
                                  >
                                    {bot.CompletedWorkflowName}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              {/* <Grid item xs={4.5}> */}
                              <Grid item xs={5}>
                                <Tooltip title={bot.FlowVarsObj}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%",
                                      p: 0.5,
                                      direction: "rtl",
                                    }}
                                  >
                                    {bot.FlowVarsObj}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              {/* <Grid item xs={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleStopBot(bot.Id)}
                            >
                              {
                                localization.chatWindow.botStatus.botsTable.body
                                  .removeBtn
                              }
                            </Button>
                          </Grid> */}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Divider flexItem sx={{ width: "100%", my: 1 }} />
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleBotStatus(
                                selectedChatUserConfig?.isBotActive
                                  ? false
                                  : true,
                              )
                            }
                          >
                            {selectedChatUserConfig?.isBotActive
                              ? localization.chatWindow.botStatus.pauseBot
                              : localization.chatWindow.botStatus.continueBot}
                          </Button>
                        </Grid>
                        <Grid item xs="auto">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleStatusItemClick(
                                selectedChatUser?.IsOpen ? false : true,
                              )
                            }
                          >
                            {selectedChatUser?.IsOpen
                              ? localization.chatWindow.botStatus.makeConvClose
                              : localization.chatWindow.botStatus.makeConvOpen}
                          </Button>
                        </Grid>
                        {botStatusObj.ActiveBots?.length > 0 && (
                          <Grid item xs="auto">
                            <Button
                              variant="contained"
                              color="primary"
                              // size="small"
                              onClick={() =>
                                handleStopBot(botStatusObj.ActiveBots[0].Id)
                              }
                            >
                              {localization.chatWindow.botStatus.removeBtn}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid item xs={12}>
                    <Typography my={0.625}>
                      {localization.chatWindow.botStatus.noStatus}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress
                  color="secondary"
                  sx={{ mx: "auto" }}
                  size={30}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>

      <Popper
        anchorEl={searchInputRef.current}
        open={searchMessagesActive}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={250}>
            {filteredSearchMessages.length ? (
              <Box>
                <FixedSizeList
                  itemCount={filteredSearchMessages.length}
                  height={400}
                  width={300}
                  itemData={filteredSearchMessages}
                  itemSize={64}
                  style={{
                    backgroundColor: "white",
                  }}
                  direction="rtl"
                >
                  {({ index, style, data }) => {
                    const message = data[index];
                    return (
                      <ListItemButton
                        style={style}
                        alignItems="flex-start"
                        key={message.msg.Id}
                        onClick={() =>
                          onSearchMessageClick(message.index, message.msg.Id)
                        }
                      >
                        {message.msg.FileType &&
                          (message.msg.FileType === "location" ||
                            message.msg.FileType === "audio" ||
                            message.msg.FileType === "image" ||
                            fileTypes.includes(
                              message.msg.FileType.toLowerCase(),
                            ) ||
                            message.msg.FileType === "contacts" ||
                            message.msg.FileType === "template" ||
                            message.msg.FileType === "video" ||
                            message.msg.FileType === "zip") && (
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: 1,
                                mt: 0.625,
                              }}
                            >
                              {message.msg.FileType === "location" && (
                                <LocationOn
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}
                              {message.msg.FileType === "audio" && (
                                <Mic
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}
                              {message.msg.FileType === "image" && (
                                <Image
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}

                              {fileTypes.includes(
                                message.msg.FileType.toLowerCase(),
                              ) && (
                                <Description
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}

                              {message.msg.FileType === "contacts" && (
                                <Person
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}

                              {message.msg.FileType === "template" && (
                                <StickyNote2
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}
                              {message.msg.FileType === "video" && (
                                <VideoCameraBack
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}
                              {message.msg.FileType === "zip" && (
                                <FolderZip
                                  sx={{
                                    color: "grey.500",
                                    fontSize: 20,
                                  }}
                                />
                              )}
                            </ListItemIcon>
                          )}
                        <ListItemText>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {message.msg.FileType === "template"
                              ? IsJsonString(message.msg.Message)
                                ? JSON.parse(message.msg.Message)["BODY"]?.text
                                : message.msg.Message
                              : message.msg.Message}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "grey.700" }}
                          >
                            {
                              <>
                                {`${String(message.msg.DateCreate).slice(
                                  8,
                                  10,
                                )}/${String(message.msg.DateCreate).slice(
                                  5,
                                  7,
                                )}/${String(message.msg.DateCreate).slice(
                                  0,
                                  4,
                                )}`}
                              </>
                            }
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    );
                  }}
                </FixedSizeList>
              </Box>
            ) : (
              <Box
                sx={{
                  width: 300,
                  bgcolor: "common.white",
                  p: 1.5,
                  boxSizing: "border-box",
                }}
              >
                <Typography variant="body2">No messages found</Typography>
              </Box>
            )}
          </Grow>
        )}
      </Popper>

      <Dialog open={isAvatarDialog} onClose={closeAvatarDialog} maxWidth="md">
        <DialogTitle>
          <Grid container spacing={1.5} justifyContent={"flex-end"}>
            {!selectedChatUser?.UserPic?.includes("app-avatar.png") && (
              <Grid item xs="auto">
                <IconButton
                  // onClick={() => closePreviewPopup()}
                  href={selectedChatUser?.UserPic}
                  target="_blank"
                >
                  <OpenInNew />
                </IconButton>
              </Grid>
            )}

            <Grid item xs="auto">
              <IconButton onClick={() => closeAvatarDialog()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {selectedChatUser?.UserPic &&
          !selectedChatUser.UserPic.includes("app-avatar.png") ? (
            <Link
              href={selectedChatUser.UserPic}
              target="_blank"
              sx={{ display: "block", maxHeight: "100%" }}
            >
              <img
                src={selectedChatUser.UserPic}
                alt=""
                style={{
                  maxWidth: 835,
                  maxHeight: "75vh",
                  objectFit: "contain",
                }}
              />
            </Link>
          ) : (
            <Avatar sx={{ width: "75vh", height: "75vh" }} />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default ChatHeader;
