import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import localizations from "../../localizations.json";
import {
  changeImage,
  docx,
  filePresent,
  locationIMG,
  paperPlaneSendIcon,
  uploadPreview,
  videoPlus,
  xlsx,
  zip,
} from "../../libs/images.js";
import {
  UseFetchGet,
  UseFetchPostAnonymos,
} from "../../hooks/fetchFunctions.js";
import { implementedVars } from "../../libs/implementedVars.js";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  AddLocationOutlined,
  AddPhotoAlternateOutlined,
  Close,
  CodeOutlined,
  CollectionsOutlined,
  DescriptionOutlined,
  DoneAll,
  EditOutlined,
  ExpandMore,
  FileUploadOutlined,
  ImageOutlined,
  OpenInNewOutlined,
  PhoneOutlined,
  QuickreplyOutlined,
  ReplyOutlined,
  Send,
  StickyNote2Outlined,
  UploadFileOutlined,
  VideoCameraBackOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { languageIcons } from "../../libs/languages.js";
import axios from "axios";
import { VisuallyHiddenInput } from "../../modules/VisuallyHiddenInput.jsx";
import FilesListLayout from "../Settings/UploadFiles/FilesListLayout.jsx";

const ParamInput = styled((props) => (
  <TextField
    size="small"
    fullWidth
    // InputProps={{ ...props.InputProps, sx: { bgcolor: "common.white" } }}
    {...props}
  />
))(({ theme }) => ({
  "& input": {
    height: "1.5em",
    // backgroundColor: "white",
    boxSizing: "content-box !important",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white", // Сделает фон белым
  },
}));

const SingleTemplate = memo(
  ({
    template,
    handleParamsMenuAnchor,
    isThisActive,
    setActiveTemplateFunction,
    whatsappTemplatesList,
    createParamsObject,
    handleSendTemplate,
    templateFileLink,
    setTemplateFileLink,
    setTemplateFileName,
    filters,
    templatesMenuRef,
    resetTemplate,
    templatesWithSend,
    noSendTemplateAction,
    noUpload,
    hebrewNamePopoverRef,
    setHebrewNamePopover,
  }) => {
    const theme = useTheme();

    const [localization, setLocalization] = useState(
      localizations["he"].templatesMenu,
    );
    const [templateHeader, setTemplateHeader] = useState(null);
    const [templateBody, setTemplateBody] = useState(null);
    const [templateFooter, setTemplateFooter] = useState(null);
    const [templateButtons, setTemplateButtons] = useState(null);

    const [thisTemplateLoading, setThisTemplateLoading] = useState(false);
    const [templateMediaLoading, setTemplateMediaLoading] = useState(false);
    const [chooseFromGallery, setChooseFromGallery] = useState(false);

    const [varsData, setVarsData] = useState([]);
    const [templateErrors, setTemplateErrors] = useState({});
    // const [templateErrors, setTemplateErrors] = useState({
    //   header: false,
    //   body: [],
    // });

    const templatePreviewsByFileType = {
      pdf: uploadPreview,
      docx: docx,
      xlsx: xlsx,
      zip: zip,
    };
    const varsRegular = /{{(\d+)}}/g;

    const headerMediaParam = varsData.find((param) => param.name === "data");
    const mediaFile = useMemo(() => {
      if (isThisActive) {
        console.log(headerMediaParam);
      }

      if (headerMediaParam) {
        return {
          type: headerMediaParam.label,
          url: headerMediaParam.text,
        };
      } else return null;
    }, [headerMediaParam?.text]);

    const hebrewName =
      whatsappTemplatesList.find((temp) => template?.id === temp.id)
        ?.hebrewName || null;
    const isVars = (() => {
      const totalCount = template.components?.reduce((count, component) => {
        const headerLocationMatch =
          component?.type === "HEADER" && component.format === "LOCATION"
            ? 4
            : 0;

        const urlMatches =
          component?.buttons?.reduce((btnCount, btn) => {
            if (btn.type === "URL") {
              const urlMatchCount = (btn.url?.match(/{{(\d+)}}/g) || []).length;
              return btnCount + urlMatchCount;
            }
            return btnCount;
          }, 0) || 0;

        const textMatches = (component?.text?.match(/{{(\d+)}}/g) || []).length;

        return count + headerLocationMatch + urlMatches + textMatches;
      }, 0);

      return {
        haveVars: totalCount > 0,
        count: totalCount,
      };
    })();

    // const [varsData, setVarsData] = useState(null);
    const [showImplementedVars, setShowImplementedVars] = useState({
      show: false,
      id: null,
    });

    const hebrewNameBtnRef = useRef(null);

    const toggleImplementedVars = (id) => {
      const prevValue = showImplementedVars.show;
      if (prevValue && id === showImplementedVars.id) {
        setShowImplementedVars({
          show: false,
          id: null,
        });
      } else {
        setShowImplementedVars({
          show: true,
          id: id,
        });
      }
    };
    const placeVarToInput = (id, value) => {
      const targetInput = templatesMenuRef.current.querySelector(
        `#param-input-${id}`,
      );

      targetInput.value = value;
      toggleImplementedVars(id);
      if (templatesWithSend) {
        createParamsObject();
      } else {
        noSendTemplateAction();
      }
    };

    function getFileFormatFromURL(url) {
      const fileExtension = url.split(".").pop();
      return fileExtension.toLowerCase();
    }

    const handleButtonHebrewNameClick = (e) => {
      const button = hebrewNameBtnRef.current;
      const top = button.offsetTop;
      const left = button.offsetLeft;

      setHebrewNamePopover({
        open: true,
        position: {
          top: top + 30,
          left: left,
        },
        template: template,
        target: e.currentTarget,
      });
    };

    //! Handle template data
    const handleTemplateComponentTextParam = (paramName, value, paramIndex) => {
      setVarsData((prevList) => {
        const newList = [...prevList];
        const targetParam = newList.find(
          (param, index) =>
            param.name === paramName &&
            (!paramName.includes("buttons") || index === paramIndex),
        );
        targetParam.text = value;
        return newList;
      });
      handleTemplateErrors({ [paramName]: value === "" });
    };
    const uploadTemplateFile = async (e) => {
      setTemplateMediaLoading(true);
      const fileLink = await handleImgUploadTemplate(e);
      if (fileLink) {
        handleTemplateComponentTextParam("data", fileLink);
      }
      setTemplateMediaLoading(false);
    };
    async function handleImgUploadTemplate(e) {
      try {
        const file = e.target.files?.[0];
        if (!file) {
          console.error("No file selected");
        }

        const formData = new FormData();
        if (file.type.startsWith("image/")) {
          formData.append("image", file);
          const response = await UseFetchPostAnonymos(
            "/api/services/GetImageLink",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else if (file.type.startsWith("application/")) {
          formData.append("file", file);
          const response = await UseFetchPostAnonymos(
            "/api/whatsapp/SaveFile",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else if (file.type.startsWith("video/")) {
          const maxSize = 16 * 1024 * 1024; // 16 MB
          if (file.size > maxSize) {
            e.target.value = null;
            return enqueueSnackbar("גודל הווידיאו חייב להיות עד 16 מגה בייט", {
              variant: "error",
            });
          }

          formData.append("file", file);
          const response = await UseFetchPostAnonymos(
            "/api/whatsapp/SaveFile",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else return null;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        setTimeout(() => {
          e.target.value = null;
        }, 200);
      }
    }
    const handleTemplateErrors = (params) => {
      setTemplateErrors((prevObj) => ({ ...prevObj, ...params }));
    };

    const checkValid = () => {
      const errorsObj = {};
      varsData.forEach((param) => {
        errorsObj[param.name] = param.text === "";
      });
      handleTemplateErrors(errorsObj);
      return !Object.values(errorsObj).some((val) => val);
    };

    const submitSendTemplate = () => {
      const isValid = checkValid();
      // const isValid = true;
      if (isValid) {
        // createParamsObject({
        //   header: templateHeader,
        //   body: templateBody,
        //   footer: templateFooter,
        //   buttons: templateButtons,
        // });
        const formattedParams = {};
        varsData.forEach((param) => {
          formattedParams[param.name] = param.text;
        });
        handleSendTemplate(template.name, formattedParams);
      }
    };

    useEffect(() => {
      //! Template header
      const headerComp = template.components?.find(
        (comp) => comp.type === "HEADER",
      );
      if (headerComp) {
        setTemplateHeader({ ...headerComp });
        // const params = [];
        // if (headerComp.format === "TEXT") {
        //   const paramsMatch = Array.from(
        //     headerComp.text?.matchAll(varsRegular),
        //     (match) => match[1],
        //   );
        //   if (paramsMatch?.length) {
        //     params.push({
        //       type: "text",
        //       text: "",
        //     });
        //   }
        // }
        // if (params.length) {
        //   setTemplateHeader({ ...headerComp, parameters: params });
        // } else {
        //   setTemplateHeader({ ...headerComp });
        // }
      }
      //! Body comp
      const bodyComp = template.components?.find(
        (comp) => comp.type === "BODY",
      );
      setTemplateBody({ ...bodyComp });
      // if (bodyComp) {
      // let bodyParams = [];
      // const paramsMatches = Array.from(
      //   bodyComp.text?.matchAll(varsRegular),
      //   (match) => match[1],
      // );

      // if (paramsMatches?.length) {
      //   bodyParams = paramsMatches.map((param) => ({
      //     type: "text",
      //     text: "",
      //   }));
      // }
      //   if (bodyParams.length) {
      //     setTemplateBody({ ...bodyComp, parameters: bodyParams });
      //   } else {

      //   }
      // }
      //! Footer comp
      const footerComp = template.components?.find(
        (comp) => comp.type === "FOOTER",
      );
      if (footerComp) {
        setTemplateFooter({ ...footerComp });
      }
      //! Buttons comp
      const buttonsComp = template.components?.find(
        (comp) => comp.type === "BUTTONS",
      );
      if (buttonsComp) {
        setTemplateButtons({ ...buttonsComp });
      }
    }, []);

    async function getTemplateVarsData() {
      try {
        setThisTemplateLoading(true);
        const res = await UseFetchGet(
          "/api/make/GetTemplateWithMediaData",
          {
            templateName: template.name,
          },
          null,
          false,
          "app",
        );
        // const res = await axios.get(
        //   `https://chrometest.codersuccess.com/api/make/GetTemplateWithMediaData?templateName=${template.name}`,
        // );

        if (res?.data?.spec?.length > 0) {
          setVarsData(res.data.spec.map((param) => ({ ...param, text: "" })));
        }
      } catch (error) {
        console.error("getTemplateVarsData: ", error);
      } finally {
        setThisTemplateLoading(false);
      }
    }

    useEffect(() => {
      if (isThisActive) {
        getTemplateVarsData();
      }
    }, [isThisActive]);

    return (
      <Box>
        {/* Template content */}
        <Box
          sx={{
            bgcolor: isThisActive ? "#f0f0f0" : "common.white",
            borderRadius: 1,
            boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            p: 1.5,
            position: "relative",
          }}
          onClick={(e) =>
            !isThisActive &&
            !hebrewNameBtnRef?.current?.contains(e.target) &&
            !hebrewNamePopoverRef?.current?.contains(e.target)
              ? setActiveTemplateFunction(template)
              : null
          }
        >
          <Grid container spacing={0}>
            {/* Template header */}
            <Grid item xs={12}>
              <Grid container spacing={1.875} alignItems={"center"}>
                {/* Template name */}
                <Grid item flexGrow={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.625,
                    }}
                  >
                    {hebrewName ? (
                      <Grid item>
                        <Tooltip
                          title={
                            filters.isHebrew
                              ? template.name
                              : hebrewName.HebrewTemaplateName
                          }
                        >
                          <Typography
                            fontSize={14}
                            sx={{
                              maxWidth: 200,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "auto",
                            }}
                          >
                            {hebrewName
                              ? filters.isHebrew
                                ? hebrewName.HebrewTemaplateName
                                : template.name
                              : template.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Fragment>
                        <Grid item>
                          <Typography
                            fontSize={14}
                            sx={{
                              maxWidth: 200,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "auto",
                            }}
                          >
                            {hebrewName
                              ? filters.isHebrew
                                ? hebrewName.HebrewTemaplateName
                                : template.name
                              : template.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            sx={{ p: 0.5, color: "text.primary" }}
                            onClick={handleButtonHebrewNameClick}
                            ref={hebrewNameBtnRef}
                          >
                            <EditOutlined sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    )}
                  </Box>
                </Grid>
                {/* Template icons */}
                <Grid item sx={{ mr: -1 }}>
                  <Grid
                    container
                    spacing={0.625}
                    alignItems={"center"}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        verticalAlign: "middle",
                      },
                    }}
                  >
                    {templateHeader?.format === "IMAGE" && (
                      <Grid item>
                        <Tooltip
                          title={localization.singleTemplate.media.image}
                        >
                          <ImageOutlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {templateHeader?.format === "VIDEO" && (
                      <Grid item>
                        <Tooltip
                          title={localization.singleTemplate.media.video}
                        >
                          <VideoCameraBackOutlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {templateHeader?.format === "DOCUMENT" && (
                      <Grid item>
                        <Tooltip title={localization.singleTemplate.media.doc}>
                          <StickyNote2Outlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      {isVars.haveVars ? (
                        <Tooltip title={"פרמטרים דינמיים"}>
                          <SvgIcon
                            sx={{
                              fontSize: 19,
                              "& text": {
                                fontWeight: "600 !important",
                              },
                            }}
                          >
                            <svg
                              width={20}
                              height={18}
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.6118 7.5C13.2681 7.5 12.9868 7.1625 12.9868 6.75V5.25C12.9868 4.0125 12.1431 3 11.1118 3H9.23682V4.5H11.1118C11.4556 4.5 11.7368 4.8375 11.7368 5.25V6.75C11.7368 7.725 12.2618 8.565 12.9868 8.8725V9.1275C12.2618 9.435 11.7368 10.2675 11.7368 11.25V12.75C11.7368 13.1625 11.4556 13.5 11.1118 13.5H9.23682V15H11.1118C12.1431 15 12.9868 13.9875 12.9868 12.75V11.25C12.9868 10.8375 13.2681 10.5 13.6118 10.5H14.2368V7.5H13.6118Z"
                                fill="black"
                                transform="translate(5)"
                              />
                              <text x={14} y={13} fontSize={14} fill="black">
                                {isVars.count}
                              </text>
                              <path
                                d="M2.98682 5.25V6.75C2.98682 7.1625 2.70557 7.5 2.36182 7.5H1.73682V10.5H2.36182C2.70557 10.5 2.98682 10.8375 2.98682 11.25V12.75C2.98682 13.9875 3.83057 15 4.86182 15H6.73682V13.5H4.86182C4.51807 13.5 4.23682 13.1625 4.23682 12.75V11.25C4.23682 10.275 3.71182 9.435 2.98682 9.1275V8.8725C3.71182 8.565 4.23682 7.725 4.23682 6.75V5.25C4.23682 4.8375 4.51807 4.5 4.86182 4.5H6.73682V3H4.86182C3.83057 3 2.98682 4.0125 2.98682 5.25Z"
                                fill="black"
                                transform="translate(-1)"
                              />
                            </svg>
                          </SvgIcon>
                        </Tooltip>
                      ) : (
                        <DescriptionOutlined sx={{ fontSize: 18 }} />
                      )}
                    </Grid>
                    <Grid item>
                      <SvgIcon
                        sx={{
                          fontSize: 18,
                        }}
                      >
                        <svg
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.0411 1.96167C5.93979 1.96167 3.4106 4.49086 3.4106 7.59217V8.29598V8.93106C3.00682 9.31609 2.70679 9.81139 2.70679 10.4074C2.70679 11.2815 3.31034 11.9297 4.07455 12.2467C4.99023 14.4354 6.82426 16.0379 9.0411 16.0379H11.1525V14.6303H9.0411C7.45157 14.6303 6.00834 13.4335 5.28285 11.5429L5.13302 11.1552L4.72062 11.0975C4.374 11.0498 4.11441 10.77 4.11441 10.4074C4.11441 10.1365 4.25124 9.92388 4.46494 9.8012L4.81822 9.59913V8.99979V8.29598C4.81822 7.89831 5.12436 7.59217 5.52204 7.59217H12.5602C12.9578 7.59217 13.264 7.89831 13.264 8.29598V8.99979V11.815H10.3869C10.3142 11.6094 10.1796 11.4314 10.0016 11.3054C9.82369 11.1794 9.61107 11.1115 9.393 11.1112C9.11301 11.1112 8.84448 11.2225 8.6465 11.4204C8.44851 11.6184 8.33729 11.887 8.33729 12.1669C8.33764 12.4336 8.4389 12.6903 8.62071 12.8853C8.80252 13.0804 9.05142 13.1994 9.3174 13.2185C9.34251 13.2213 9.36775 13.2226 9.393 13.2227H13.9678H14.6716C15.4458 13.2227 16.0792 12.5892 16.0792 11.815V10.4074C16.0792 9.63322 15.4458 8.99979 14.6716 8.99979V8.29598V7.59217C14.6716 4.49086 12.1424 1.96167 9.0411 1.96167ZM9.0411 3.36929C10.9101 3.36929 12.4832 4.57097 13.0427 6.2464C12.8869 6.2092 12.7266 6.18454 12.5602 6.18454H5.52204C5.35558 6.18454 5.19533 6.2092 5.03954 6.2464C5.59902 4.57097 7.17206 3.36929 9.0411 3.36929Z"
                            fill="#2A3847"
                          />
                        </svg>
                      </SvgIcon>
                    </Grid>
                    {languageIcons[template.language] && (
                      <Grid item>{languageIcons[template.language]}</Grid>
                    )}
                    <Grid item>
                      <IconButton
                        onClick={isThisActive ? resetTemplate : null}
                        size="small"
                        sx={{
                          ml: 1,
                          transform: isThisActive
                            ? "rotate(180deg)"
                            : "rotate(0)",
                          p: 0.25,
                        }}
                      >
                        <ExpandMore
                          sx={{ fontSize: 20, color: "common.black" }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Template text preview */}
            <Grid item xs={12}>
              <Typography fontSize={13} color="text.secondary">
                {template.components
                  .find((item) => item.type === "BODY")
                  .text.substring(0, 45)
                  .replace(/^[a-z\u05D0-\u05EA]+$/i, "")}
              </Typography>
            </Grid>
            {/* Template body (display if active) */}
            {isThisActive && (
              <Fragment>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* Template preview */}
                    <Grid item xs={12} sx={{ mt: 1.25 }}>
                      <Box
                        sx={{
                          position: "relative",
                          pl: 1.25,
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: "calc(100% - 10px)",
                            width: 0,
                            height: 0,
                            borderRight: "10px solid rgba(25, 118, 210, 0.12)",
                            borderBottom: "15px solid transparent",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            backgroundColor: "rgba(25, 118, 210, 0.12)",
                            minWidth: 240,
                            maxWidth: 325,
                            p: 1.25,
                            borderRadius: " 0 8px 8px 8px",
                          }}
                        >
                          <Grid container spacing={1}>
                            {templateHeader && (
                              <Grid item xs={12} position={"relative"}>
                                {templateHeader.format === "TEXT" ? (
                                  <Typography
                                    variant="body2"
                                    fontSize={14}
                                    fontWeight={700}
                                    sx={{
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {varsData.find(
                                      (param) => param.name === "header_1",
                                    ) &&
                                    varsData.find(
                                      (param) => param.name === "header_1",
                                    )?.text !== ""
                                      ? templateHeader.text.replace(
                                          "{{1}}",
                                          varsData.find(
                                            (param) =>
                                              param.name === "header_1",
                                          ).text,
                                        )
                                      : templateHeader.text}
                                  </Typography>
                                ) : mediaFile?.url ? (
                                  mediaFile.type === "video" ? (
                                    <video
                                      src={mediaFile?.url}
                                      controls={false}
                                      style={{
                                        width: "100%",
                                        height: 175,
                                        background: theme.palette.common.white,
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: 175,
                                        background: theme.palette.common.white,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={
                                          mediaFile.type === "image"
                                            ? mediaFile.url
                                            : templatePreviewsByFileType[
                                                getFileFormatFromURL(
                                                  mediaFile.url,
                                                )
                                              ]
                                        }
                                        alt=""
                                        style={{
                                          width:
                                            mediaFile.type !== "image"
                                              ? "65%"
                                              : "100%",
                                          height:
                                            mediaFile.type !== "image"
                                              ? "65%"
                                              : "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: 175,
                                      background: theme.palette.common.white,
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      gap: 0.25,
                                      justifyContent: "center",
                                      color: theme.palette.secondary.main,
                                    }}
                                  >
                                    {templateHeader.format === "IMAGE" ? (
                                      <AddPhotoAlternateOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "VIDEO" ? (
                                      <VideoFileOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "DOCUMENT" ? (
                                      <UploadFileOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "LOCATION" ? (
                                      <AddLocationOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <Typography
                                      fontSize={15}
                                      fontWeight={700}
                                      width="100%"
                                      textAlign={"center"}
                                    >
                                      העלאת קובץ
                                    </Typography>
                                  </Box>
                                )}
                                {["IMAGE", "DOCUMENT", "VIDEO"].includes(
                                  templateHeader.format,
                                ) && (
                                  <Box
                                    component={"label"}
                                    sx={{
                                      pointerEvents: noUpload ? "none" : null,
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      zIndex: 100,
                                    }}
                                    htmlFor="template-header-media-upload"
                                  />
                                )}
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                fontSize={14}
                                whiteSpace={"pre-line"}
                              >
                                {varsData.some((param) =>
                                  param.name.startsWith("body"),
                                )
                                  ? templateBody.text.replaceAll(
                                      /\{\{(\d+)\}\}/g,
                                      (_, match) => {
                                        const param = varsData.find(
                                          (param) =>
                                            param.name ===
                                            `body_${parseInt(match)}`,
                                        );
                                        return param && param.text !== ""
                                          ? param.text
                                          : `{{${match}}}`;
                                      },
                                    )
                                  : templateBody?.text}
                              </Typography>
                            </Grid>

                            {templateFooter && (
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  fontSize={13}
                                >
                                  {templateFooter.text}
                                </Typography>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Grid container spacing={1} alignItems={"center"}>
                                <Grid item>
                                  <DoneAll
                                    sx={{
                                      fontSize: 16,
                                      color: "text.secondary",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="body2"
                                    color={"rgba(0, 0, 0, 0.38)"}
                                    fontSize={12}
                                  >
                                    12/12/2022 10:25
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {templateButtons && (
                              <Grid item xs={12}>
                                {templateButtons.buttons?.map(
                                  (button, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        mx: -1.25,
                                        px: 1.25,
                                        borderTop: "1px solid #c2c2c2",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          gap: 1,
                                          p: 1,
                                          color: theme.palette.secondary.main,
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {button.text}
                                        </Typography>
                                        {button.type === "QUICK_REPLY" && (
                                          <ReplyOutlined />
                                        )}
                                        {button.type === "URL" && (
                                          <OpenInNewOutlined />
                                        )}
                                        {button.type === "PHONE_NUMBER" && (
                                          <PhoneOutlined />
                                        )}
                                        {button.type === "COPY_CODE" && (
                                          <CodeOutlined />
                                        )}
                                      </Box>
                                    </Box>
                                  ),
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    {thisTemplateLoading ? (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 50,
                          }}
                        >
                          <CircularProgress size={32} color="secondary" />
                        </Box>
                      </Grid>
                    ) : (
                      <Fragment>
                        {varsData.length > 0 && (
                          <Grid item xs={12}>
                            <Grid container spacing={1.25}>
                              <Grid item xs={12}>
                                <Typography fontSize={14} fontWeight={600}>
                                  {localization.componentsTitle}
                                </Typography>
                              </Grid>

                              {varsData.map((param, index) => {
                                const prevParam = varsData[index - 1];
                                return (
                                  <Fragment key={`${param.name}-${index}`}>
                                    {param.name.replace(/\d+/g, "") !==
                                      prevParam?.name.replace(/\d+/g, "") && (
                                      <Grid item xs={12}>
                                        <Typography
                                          fontSize={14}
                                          fontWeight={600}
                                        >
                                          {param.name === "data"
                                            ? localization.header
                                            : localization[
                                                param.name
                                                  .replace(/\d+/g, "")
                                                  .slice(0, -1)
                                              ]}
                                        </Typography>
                                      </Grid>
                                    )}
                                    <Grid item xs={12} key={param.name}>
                                      <ParamInput
                                        value={param.text}
                                        error={templateErrors?.[param.name]}
                                        onChange={(e) =>
                                          handleTemplateComponentTextParam(
                                            param.name,
                                            e.target.value,
                                            index,
                                          )
                                        }
                                        label={
                                          param.name === "data"
                                            ? param.label
                                            : `{{${param.name.replaceAll(
                                                /\D/g,
                                                "",
                                              )}}}`
                                        }
                                        InputProps={{
                                          endAdornment:
                                            param.name === "data" ? (
                                              <InputAdornment position="end">
                                                {templateMediaLoading ? (
                                                  <CircularProgress
                                                    size={20}
                                                    color="secondary"
                                                  />
                                                ) : (
                                                  <Fragment>
                                                    <IconButton
                                                      size="small"
                                                      component="label"
                                                    >
                                                      <FileUploadOutlined />
                                                      <VisuallyHiddenInput
                                                        type="file"
                                                        accept={
                                                          param.label ===
                                                          "image"
                                                            ? "image/*"
                                                            : param.label ===
                                                              "document"
                                                            ? ".docx,.doc, .sls,.xlsx,.pdf,.zip"
                                                            : param.label ===
                                                              "video"
                                                            ? "video/*"
                                                            : null
                                                        }
                                                        onChange={
                                                          uploadTemplateFile
                                                        }
                                                        id="template-header-media-upload"
                                                      />
                                                    </IconButton>
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        setChooseFromGallery(
                                                          param.label,
                                                        )
                                                      }
                                                    >
                                                      <CollectionsOutlined />
                                                    </IconButton>
                                                  </Fragment>
                                                )}
                                              </InputAdornment>
                                            ) : (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  size="small"
                                                  onClick={(e) =>
                                                    handleParamsMenuAnchor(
                                                      e,
                                                      param,
                                                      index,
                                                      handleTemplateComponentTextParam,
                                                    )
                                                  }
                                                >
                                                  <QuickreplyOutlined />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                      />
                                    </Grid>
                                  </Fragment>
                                );
                              })}
                            </Grid>
                          </Grid>
                        )}
                        {templatesWithSend && (
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              onClick={submitSendTemplate}
                              endIcon={
                                <Send
                                  sx={{
                                    color: "common.white",
                                  }}
                                />
                              }
                              fullWidth
                              sx={{
                                p: "6px 16px !important",
                                // width: 200,
                                display: "flex",
                                margin: "0 0 0 auto !important",
                                "& .MuiButton-icon": {
                                  marginRight: "-4px !important",
                                  marginLeft: "8px !important",
                                  transform: "rotate(-180deg)",
                                },
                              }}
                              disabled={thisTemplateLoading}
                            >
                              {localization.singleTemplate.send}
                            </Button>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  </Grid>

                  {/* </Grid> */}
                </Grid>

                <Dialog
                  open={Boolean(chooseFromGallery)}
                  onClose={() => setChooseFromGallery(false)}
                  maxWidth={"lg"}
                  fullWidth
                >
                  <DialogTitle justifyContent={"flex-end"}>
                    <IconButton onClick={() => setChooseFromGallery(false)}>
                      <Close />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <FilesListLayout
                      type={chooseFromGallery}
                      companyId={null}
                      FunnerUserId={null}
                      noActions={true}
                      onClickAction={(file) => {
                        handleTemplateComponentTextParam("data", file.Link);
                        setChooseFromGallery(false);
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </Fragment>
            )}
          </Grid>
        </Box>
      </Box>
    );
  },
);

export default SingleTemplate;
