import React, { Fragment, useEffect, useRef, useState } from "react";
import { applicationType } from "../../../applicationType";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IsJsonString } from "../../../libs/helpers";
import localizations from "../../../localizations.json";
import {
  powerlinkIcon,
  powerlinkIconOrange,
  priorityDocLogo,
  priorityLetterLogo,
} from "../../../libs/images";
import { Send } from "@mui/icons-material";
import reactDom from "react-dom";
import SmilesPanelBottom from "../SmilesPanel/SmilesPanelBottom";

function SendMessageForm({
  funnerUsers,
  textareaRef,
  // setTextareaValue,
  isTemplateSending,
  // textareaValue,
  sendToPlatformsStatus,
  isActive,
  selectedChatUser,
  handleUpload,
  userConfig,
  resetTemplateFromChat,
  toggleAccordion,
  chatType,
  toggleSmilesPanelBottom,
  companyType,
  linkToObjectNum,
  sendDocsMenuVisible,
  sendDocsMenuRef,
  handleSendDocsMenuVisible,
  sendDocsBtnRef,
  handlePrioritySendDocsWindow,
  sendUpload,
  sendUploadPriority,
  isMessageSending,
  handleForm,
  resetTextArea,
  smilesPanelContainerRef,
  isSmileBottomPanelOpen,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.inputArea,
  );
  const [textareaValue, setTextareaValue] = useState("");
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

  const [isNoteContacts, setIsNoteContacts] = useState(false);
  const [noteContactsList, setNoteContactsList] = useState([]);

  const sendBtnRef = useRef(null);

  const findContactTextAreaHandler = (e) => {
    const textarea = textareaRef.current;
    const inputText = textarea.value;
    const lastWord = inputText.split(" ").pop();

    if (funnerUsers?.length) {
      if (lastWord.indexOf("@") === 0) {
        setIsNoteContacts(true);

        const currentValue = lastWord.slice(1).toLowerCase();
        if (currentValue !== "") {
          const filteredArray = funnerUsers
            ?.filter((user) => {
              const userProfileConfig = IsJsonString(user.ProfileConfig)
                ? JSON.parse(user.ProfileConfig)
                : {};

              const visible =
                userProfileConfig?.funnerInfo?.visibleInList !== undefined &&
                userProfileConfig?.funnerInfo?.visibleInList !== null
                  ? userProfileConfig.funnerInfo.visibleInList
                  : user.IsActive;

              return visible;
            })
            .filter((contact) =>
              `${contact.FirstName || ""} ${contact.LastName || ""}`
                ?.toLowerCase()
                .includes(currentValue),
            );
          console.log(filteredArray);
          setNoteContactsList(filteredArray);
        } else {
          setNoteContactsList([
            ...funnerUsers
              .filter((user) => {
                const userProfileConfig = IsJsonString(user.ProfileConfig)
                  ? JSON.parse(user.ProfileConfig)
                  : {};

                const visible =
                  userProfileConfig?.funnerInfo?.visibleInList !== undefined &&
                  userProfileConfig?.funnerInfo?.visibleInList !== null
                    ? userProfileConfig.funnerInfo.visibleInList
                    : user.IsActive;

                return visible;
              })
              .filter(
                (contact) =>
                  `${contact.FirstName || ""} ${contact.LastName || ""}`,
              ),
          ]);
        }
      } else setIsNoteContacts(false);
    }
  };
  const adminUserListClick = (contact) => {
    const textarea = textareaRef.current;
    const userName = contact.FullName;
    const inputText = textarea.value;
    const lastWord = inputText.split(" ").pop();
    const lastWordPos = inputText.length - lastWord.length;

    const textareaNewText = inputText.slice(0, lastWordPos + 1) + userName;
    setTextareaValue(textareaNewText);
    setIsNoteContacts(false);
    textarea.focus();
  };

  const handleTextareaText = (e) => {
    const input = e.target;
    setTextareaValue(input.value);
  };
  const handleTextAreaKeyDown = (event) => {
    const currentSubmitType = userConfig.submitType;

    // const checkedSubmitType = submitType.find((type) => type.checked);
    const checkedSubmitTypeCtrlEnter = currentSubmitType?.ctrlEnter.find(
      (type) => type.checked,
    );
    const checkedSubmitTypeShiftEnter = currentSubmitType?.shiftEnter.find(
      (type) => type.checked,
    );
    const checkedSubmitTypeEnter = currentSubmitType?.enter.find(
      (type) => type.checked,
    );

    const selectedSubmitTypeCtrlEnter = checkedSubmitTypeCtrlEnter.text;
    const selectedSubmitTypeShiftEnter = checkedSubmitTypeShiftEnter.text;
    const selectedSubmitTypeEnter = checkedSubmitTypeEnter.text;

    const sendBtn = sendBtnRef.current;
    const isCtrlEnterMustSend = selectedSubmitTypeCtrlEnter === "שלח הודעה";
    const isShiftEnterMustSend = selectedSubmitTypeShiftEnter === "שלח הודעה";
    const isEnterMustSend = selectedSubmitTypeEnter === "שלח הודעה";

    if (
      //! Send if combination clicked
      (isCtrlEnterMustSend &&
        event.key === "Enter" &&
        (event.ctrlKey || event.metaKey)) ||
      (isShiftEnterMustSend && event.key === "Enter" && event.shiftKey) ||
      (isEnterMustSend &&
        event.key === "Enter" &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey)
    ) {
      event.preventDefault();
      if (!event.target.value || event.target.value === "") return;
      sendBtn.click();
    } else if (
      //! Break line if combination clicked
      (!isCtrlEnterMustSend &&
        event.key === "Enter" &&
        (event.ctrlKey || event.metaKey)) ||
      (!isShiftEnterMustSend && event.key === "Enter" && event.shiftKey) ||
      (!isEnterMustSend &&
        event.key === "Enter" &&
        !event.shiftKey &&
        !event.metaKey)
    ) {
      event.preventDefault();
      // const newValue = textareaRef.current.value + "\n";
      // setTextareaValue(newValue);
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);

      // textarea.value = before + "\n" + after;
      setTextareaValue(before + "\n" + after);
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      }, 50);

      textarea.focus();
    }
  };
  function resetTextArea() {
    setTextareaValue("");
  }

  const copyPasteUpload = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    if (clipboardData.files && clipboardData.files.length > 0) {
      handleUpload(clipboardData.files);
    }
  };

  const onUserChangedActions = () => {
    resetTextArea();
  };

  useEffect(() => {
    onUserChangedActions();
  }, [selectedChatUser?.UserDisplayPhoneNumber]);

  return (
    <Fragment>
      <Grid
        container
        spacing={1.5}
        alignItems={applicationType === "web" ? "flex-end" : null}
      >
        <Grid
          item
          xs={applicationType === "web" ? null : 12}
          flexGrow={applicationType === "web" ? 1 : null}
        >
          <div className="form-group">
            <div
              className={`choose-contact-list-wrapper ${
                isNoteContacts && noteContactsList.length > 0 ? "active" : ""
              }`}
            >
              <ul className="choose-contact-list">
                {noteContactsList.map((contact) => (
                  <li
                    key={contact.Id}
                    onClick={() => adminUserListClick(contact)}
                  >
                    {contact.FullName}
                  </li>
                ))}
              </ul>
            </div>
            <TextField
              value={
                isTemplateSending
                  ? localization.textarea.templateMessageText
                  : textareaValue
              }
              placeholder={localization.textarea.placeholder}
              inputRef={textareaRef}
              onChange={handleTextareaText}
              InputProps={{
                readOnly:
                  (sendToPlatformsStatus !== "note" && !isActive) ||
                  !selectedChatUser ||
                  isTemplateSending,
                sx: {
                  fontSize: 14,
                },
                // onFocus: () => console.log("textarea focus"),
                // onBlur: () => console.log("textarea blur"),
              }}
              disabled={
                (sendToPlatformsStatus !== "note" && !isActive) ||
                !selectedChatUser ||
                isTemplateSending
              }
              onInput={(e) => {
                if (sendToPlatformsStatus === "note") {
                  findContactTextAreaHandler(e);
                }
              }}
              onPaste={copyPasteUpload}
              onKeyDown={handleTextAreaKeyDown}
              multiline
              minRows={1}
              maxRows={5}
              fullWidth
              size="small"
              sx={{
                bgcolor: "common.white",
              }}
            />
            {isTemplateSending && (
              <span
                className="delete-template-choice"
                onClick={resetTemplateFromChat}
              >
                x
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={applicationType === "web" ? "auto" : 12}>
          <div className="chat-input-actions__bottom">
            {applicationType === "extension" && (
              <Grid item>
                <div
                  className="chat-input-actions__bottom-right-buttons"
                  id="funner-global__buttons-interaction-wrapper"
                >
                  <Divider
                    orientation="vertical"
                    flexItem={true}
                    sx={{ mr: 0.5, my: 0.5 }}
                  />
                  <IconButton
                    onClick={() => toggleAccordion()}
                    size={"small"}
                    color="primary"
                    sx={{
                      width: 34,
                      height: 34,
                      transition: "color 0.15s ease 0s",
                    }}
                    disabled={
                      sendToPlatformsStatus === "note" || !selectedChatUser
                    }
                  >
                    <SvgIcon sx={{ width: 20, height: 20 }}>
                      <svg
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.83335 2.49992H3.33335V4.99992H1.66669V0.833252H5.83335V2.49992ZM18.3334 4.99992V0.833252H14.1667V2.49992H16.6667V4.99992H18.3334ZM5.83335 17.4999H3.33335V14.9999H1.66669V19.1666H5.83335V17.4999ZM16.6667 14.9999V17.4999H14.1667V19.1666H18.3334V14.9999H16.6667ZM14.1667 4.99992H5.83335V14.9999H14.1667V4.99992ZM15.8334 14.9999C15.8334 15.9166 15.0834 16.6666 14.1667 16.6666H5.83335C4.91669 16.6666 4.16669 15.9166 4.16669 14.9999V4.99992C4.16669 4.08325 4.91669 3.33325 5.83335 3.33325H14.1667C15.0834 3.33325 15.8334 4.08325 15.8334 4.99992V14.9999ZM12.5 6.66658H7.50002V8.33325H12.5V6.66658ZM12.5 9.16658H7.50002V10.8333H12.5V9.16658ZM12.5 11.6666H7.50002V13.3333H12.5V11.6666Z" />
                      </svg>
                    </SvgIcon>
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    flexItem={true}
                    sx={{ mx: 0.5, my: 0.5 }}
                  />
                  <IconButton
                    component="label"
                    htmlFor={`${chatType}-contained-button-file`}
                    size="small"
                    color="primary"
                    sx={{
                      width: 34,
                      height: 34,
                      transition: "color 0.15s ease 0s",
                    }}
                    disabled={
                      sendToPlatformsStatus === "note" ||
                      (sendToPlatformsStatus !== "note" && !isActive)
                    }
                  >
                    <SvgIcon sx={{ width: 24, height: 24 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="currentColor"
                      >
                        <g id="FilePresentOutlined">
                          <path
                            id="Vector"
                            d="M15.1666 2.16675H6.49998C5.30831 2.16675 4.33331 3.14175 4.33331 4.33341V21.6667C4.33331 22.8584 5.30831 23.8334 6.49998 23.8334H19.5C20.6916 23.8334 21.6666 22.8584 21.6666 21.6667V8.66675L15.1666 2.16675ZM19.5 21.6667H6.49998V4.33341H15.1666V8.66675H19.5V21.6667ZM13 18.4167C11.8083 18.4167 10.8333 17.4417 10.8333 16.2501V10.2917C10.8333 9.98841 11.0716 9.75008 11.375 9.75008C11.6783 9.75008 11.9166 9.98841 11.9166 10.2917V16.2501H14.0833V10.2917C14.0833 8.79675 12.87 7.58342 11.375 7.58342C9.87998 7.58342 8.66665 8.79675 8.66665 10.2917V16.2501C8.66665 18.6442 10.6058 20.5834 13 20.5834C15.3941 20.5834 17.3333 18.6442 17.3333 16.2501V11.9167H15.1666V16.2501C15.1666 17.4417 14.1916 18.4167 13 18.4167Z"
                          />
                        </g>
                      </svg>
                    </SvgIcon>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={`${chatType}-contained-button-file`}
                      multiple={true}
                      onChange={(e) => {
                        handleUpload(e.target.files);
                        e.target.value = null;
                      }}
                    />
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    flexItem={true}
                    sx={{ mx: 0.5, my: 0.5 }}
                  />
                  <IconButton
                    onClick={toggleSmilesPanelBottom}
                    size={"small"}
                    color="primary"
                    sx={{
                      width: 34,
                      height: 34,
                      transition: "color 0.15s ease 0s",
                    }}
                    disabled={!isActive}
                  >
                    <SvgIcon sx={{ width: 24, height: 24 }}>
                      <svg
                        viewBox="0 0 26 26"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.9892 2.16675C7.00919 2.16675 2.16669 7.02008 2.16669 13.0001C2.16669 18.9801 7.00919 23.8334 12.9892 23.8334C18.98 23.8334 23.8334 18.9801 23.8334 13.0001C23.8334 7.02008 18.98 2.16675 12.9892 2.16675ZM13 21.6667C8.21169 21.6667 4.33335 17.7884 4.33335 13.0001C4.33335 8.21175 8.21169 4.33341 13 4.33341C17.7884 4.33341 21.6667 8.21175 21.6667 13.0001C21.6667 17.7884 17.7884 21.6667 13 21.6667Z" />
                        <path d="M13 19.5001C15.47 19.5001 17.5717 17.7017 18.4167 15.1667H7.58335C8.42835 17.7017 10.53 19.5001 13 19.5001Z" />
                        <path d="M9.20835 11.9167C10.1058 11.9167 10.8334 11.1892 10.8334 10.2917C10.8334 9.39429 10.1058 8.66675 9.20835 8.66675C8.31089 8.66675 7.58335 9.39429 7.58335 10.2917C7.58335 11.1892 8.31089 11.9167 9.20835 11.9167Z" />
                        <path d="M16.7917 11.9167C17.6891 11.9167 18.4167 11.1892 18.4167 10.2917C18.4167 9.39429 17.6891 8.66675 16.7917 8.66675C15.8942 8.66675 15.1667 9.39429 15.1667 10.2917C15.1667 11.1892 15.8942 11.9167 16.7917 11.9167Z" />
                      </svg>
                    </SvgIcon>
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    flexItem={true}
                    sx={{ mx: 0.5, my: 0.5 }}
                  />
                  {companyType === "fireberry" && (
                    <Fragment>
                      <IconButton
                        size={"small"}
                        color="primary"
                        sx={{ width: 34, height: 34 }}
                        LinkComponent={"a"}
                        href={
                          selectedChatUser?.fireberryAccountLink
                            ? selectedChatUser.fireberryAccountLink
                            : `https://app.powerlink.co.il/app/views/${
                                linkToObjectNum === "second" ? "2" : "1"
                              }?q=${selectedChatUser?.UserDisplayPhoneNumber?.replace(
                                "972",
                                "0",
                              )}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {selectedChatUser?.fireberryAccountLink &&
                        !selectedChatUser.fireberryAccountLink.includes(
                          "?q=",
                        ) ? (
                          <img
                            src={powerlinkIcon}
                            style={{ width: 22, height: 22 }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={powerlinkIconOrange}
                            style={{ width: 22, height: 22 }}
                            alt=""
                          />
                        )}
                      </IconButton>
                      <Divider
                        orientation="vertical"
                        flexItem={true}
                        sx={{ ml: 0.5, my: 0.5 }}
                      />
                    </Fragment>
                  )}

                  {companyType === "priority" && (
                    <div
                      className={`chat-input-actions__docs-menu-wrapper material-dropdown material-dropdown-common ${
                        sendDocsMenuVisible ? "active" : ""
                      }`}
                      ref={sendDocsMenuRef}
                    >
                      <IconButton
                        onClick={() =>
                          handleSendDocsMenuVisible(!sendDocsMenuVisible)
                        }
                        size={"small"}
                        color="primary"
                        ref={sendDocsBtnRef}
                        sx={{
                          width: 34,
                          height: 34,
                          transition: "color 0.15s ease 0s",
                        }}
                      >
                        <img
                          src={priorityDocLogo}
                          alt=""
                          style={{
                            width: 20,
                            height: 20,
                            objectFit: "contain",
                          }}
                        />
                      </IconButton>
                      <ul
                        className="material-dropdown__body"
                        style={{
                          bottom: "calc(100% + 3px)",
                          top: "auto",
                          width: 220,
                          right: "-24px",
                          borderRadius: 4,
                          boxShadow:
                            "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                        }}
                      >
                        {companyType === "priority" && (
                          <li
                            style={{
                              display: "flex",
                              alignItems: "center, gap: 9px",
                            }}
                            className="_priority-send-docs"
                            onClick={() => {
                              handlePrioritySendDocsWindow(true);
                              handleSendDocsMenuVisible(false);
                            }}
                          >
                            <div
                              className="funner-icon"
                              style={{
                                width: 23,
                                flex: "0 0 23px",
                                height: 20,
                                marginLeft: 8,
                              }}
                            >
                              <img src={priorityLetterLogo} alt="" />
                            </div>
                            מסמך מפריוריטי
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </Grid>
            )}
            {((sendToPlatformsStatus === "note" && selectedChatUser) ||
              (sendToPlatformsStatus !== "note" && isActive)) && (
              <Button
                variant="contained"
                color={
                  sendToPlatformsStatus === "note" ? "customPurple" : "primary"
                }
                // disabled={
                //   (!textareaValue.length &&
                //     !sendUpload?.isUpload &&
                //     !sendUploadPriority?.isUpload) ||
                //   isMessageSending
                // }
                sx={{
                  height: 37,
                  minHeight: 37,
                  minWidth: matchesMobile ? "none" : 120,
                  pointerEvents:
                    (!textareaValue.length &&
                      !sendUpload?.isUpload &&
                      !sendUploadPriority?.isUpload) ||
                    isMessageSending
                      ? "none"
                      : null,
                  opacity:
                    (!textareaValue.length &&
                      !sendUpload?.isUpload &&
                      !sendUploadPriority?.isUpload) ||
                    isMessageSending
                      ? 0.5
                      : 1,
                  transition: (theme) =>
                    `${theme.transitions.create(
                      ["color", "background-color", "opacity"],
                      {
                        duration: theme.transitions.duration.short,
                      },
                    )}, opacity 0.3s`,
                  flexGrow: 1,

                  [theme.breakpoints.down(480)]: {
                    "& .MuiButton-endIcon": {
                      ml: 0,
                    },
                  },
                }}
                endIcon={
                  <Send
                    sx={{
                      color: "common.white",
                      transform: "rotate(180deg)",
                    }}
                  />
                }
                onClick={() => {
                  handleForm(textareaValue);
                  resetTextArea();
                }}
                ref={sendBtnRef}
              >
                {!matchesMobile &&
                  (sendToPlatformsStatus === "note"
                    ? localization.sendBtn.sendNote
                    : localization.sendBtn.default)}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      {smilesPanelContainerRef.current &&
        reactDom.createPortal(
          <SmilesPanelBottom
            textareaRef={textareaRef}
            isOpen={isSmileBottomPanelOpen}
            // textareaValue={textareaValue}
            setTextareaValue={setTextareaValue}
            toggleSmilesPanelBottom={toggleSmilesPanelBottom}
          />,
          smilesPanelContainerRef.current,
        )}
    </Fragment>
  );
}

export default SendMessageForm;
