import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import localizations from "../../../localizations.json";
import {
  instagramRounded,
  powerlinkIcon,
  powerlinkIconOrange,
  priorityDocLogo,
  priorityLetterLogo,
  recordChatSmallIcon,
} from "../../../libs/images";
import SmilesPanelBottom from "../SmilesPanel/SmilesPanelBottom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, LinkOutlined, ShortcutOutlined } from "@mui/icons-material";
import { applicationType } from "../../../applicationType";
import { IsJsonString } from "../../../libs/helpers";
import SendMessageForm from "./SendMessageForm";
import FilesListLayout from "../../Settings/UploadFiles/FilesListLayout";

function ChatInputArea({
  textareaRef,
  sendToPlatformsStatus,
  switchToChatType,
  selectedChatUser,
  toggleAccordion,
  businessInfo,
  isMessageSending,
  handleForm,
  isActive,
  handleUpload,
  sendUpload,
  sendUploadPriority,
  funnerUsers,
  userConfig,
  handlePrioritySendDocsWindow,
  isTemplateSending,
  resetTemplateFromChat,
  chatType,
  companyType,
  isSmileBottomPanelOpen,
  setIsSmileBottomPanelOpen,
  forwardMessage,
  handleForwardMessage,
  closeForwardMessage,
  forwardMessagesWebhookList,
  handleMessagesToWebhookPopup,
  forwardMessagesWebhookSelectable,
  resetForwardMessagesToWebhook,
  isButtonsPopupForForwardMessagesWebhook,
  forwardMessagesToWebhook,
  handleDirectFileUpload,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.inputArea,
  );
  const isFireberryPage = window.location.href.includes("app.fireberry.com");

  //! Views and states
  const [isDragging, setIsDragging] = useState(false);
  const [sendDocsMenuVisible, setSendDocsMenuVisible] = useState(false);
  // const [textareaValue, setTextareaValue] = useState("");
  const [uploadMenuAnchor, setUploadMenuAnchor] = useState(null);
  const [chooseFromGallery, setChooseFromGallery] = useState(false);

  const additionalLink = useMemo(() => {
    let additionalLink = {
      enabled: false,
      url: "",
      icon: null,
    };
    if (IsJsonString(businessInfo?.CompanyPlatforms)) {
      const platformsObject = JSON.parse(businessInfo.CompanyPlatforms);
      const additionalLinkObj = platformsObject.additionalLink;
      if (additionalLinkObj) {
        additionalLink = platformsObject.additionalLink;
      }
    }
    return additionalLink;
  }, [businessInfo?.CompanyPlatforms]);

  //! Refs
  const inputAreaRef = useRef(null);
  const sendDocsMenuRef = useRef(null);
  const sendDocsBtnRef = useRef(null);
  const smilesPanelContainerRef = useRef(null);
  const uploadLabelRef = useRef(null);

  //! Functions and handlers
  //* Drag&Drop and Copypaste
  const handleOnDrop = (e) => {
    e.preventDefault();
    handleUpload(e.dataTransfer.files);
    setIsDragging(false);
  };
  const handleOnDragEnter = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };
  const handleOnDragLeave = (e) => {
    e.preventDefault();
    const rect = inputAreaRef.current.getBoundingClientRect();
    const isOutside =
      e.clientX < rect.left ||
      e.clientX > rect.right ||
      e.clientY < rect.top ||
      e.clientY > rect.bottom;

    if (isOutside) {
      setIsDragging(false);
    }
  };
  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  //* Upload
  const handleUploadMenu = (e) => {
    if (uploadLabelRef.current.contains(e.target)) return;
    setUploadMenuAnchor(e.currentTarget);
  };
  const uploadFromMediaGallery = () => {
    setUploadMenuAnchor(null);
    setChooseFromGallery("image");
  };
  const uploadFromComputer = () => {
    setUploadMenuAnchor(null);
    uploadLabelRef.current.click();
  };

  const toggleSmilesPanelBottom = () => {
    const newValue = !isSmileBottomPanelOpen;
    setIsSmileBottomPanelOpen(newValue);
  };
  const handleSendDocsMenuVisible = (state) => {
    setSendDocsMenuVisible(state);
  };

  const onOutsideElementsClick = (e) => {
    const targetClicked = e.target;

    if (
      sendDocsMenuRef.current &&
      sendDocsBtnRef.current &&
      !sendDocsMenuRef.current.contains(targetClicked) &&
      !sendDocsBtnRef.current.contains(targetClicked)
    ) {
      handleSendDocsMenuVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", onOutsideElementsClick);
    return () => {
      document.removeEventListener("click", onOutsideElementsClick);
    };
  }, []);

  return (
    <Box
      className={`pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area ${
        isDragging ? "file-drag" : ""
      }`}
      ref={inputAreaRef}
      onDrop={isFireberryPage ? null : handleOnDrop}
      onDragEnter={isFireberryPage ? null : handleOnDragEnter}
      onDragLeave={isFireberryPage ? null : handleOnDragLeave}
      onDragOver={isFireberryPage ? null : handleOnDragOver}
      sx={{
        pt: applicationType === "extension" ? 2.5 : 3,
        pb: applicationType === "extension" ? 2.5 : 4,
        // pt: 1.5,
        // pb: 1,
        pl: 1.75,
        pr: 3.25,
      }}
    >
      <div className={"chat-input-area__drag-preview"}>
        <div>Drag your file here to send</div>
      </div>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10000000000000000000000000000n,
          bgcolor: "#eee",
          transform:
            forwardMessage.visible || forwardMessagesWebhookSelectable
              ? "translateY(0)"
              : "translateY(101%)",
          transition: "transform 0.3s ease 0s",
        }}
      >
        <Grid
          container
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={3}
        >
          <Grid item>
            <Typography fontWeight={500} fontSize={20}>
              {localizations["he"].chat.messages.forwardMessages.selectedCount}:{" "}
              {forwardMessage.visible
                ? forwardMessage.messagesList?.length
                : forwardMessagesWebhookSelectable
                ? forwardMessagesWebhookList.length
                : 0}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={
                forwardMessage.visible
                  ? !forwardMessage.messagesList?.length
                  : forwardMessagesWebhookSelectable
                  ? !forwardMessagesWebhookList.length
                  : true
              }
              sx={{
                opacity:
                  (forwardMessage.visible &&
                    !forwardMessage.messagesList?.length) ||
                  (forwardMessagesWebhookSelectable &&
                    !forwardMessagesWebhookList.length)
                    ? 0.5
                    : 1,
              }}
              onClick={() =>
                forwardMessage.visible
                  ? handleForwardMessage({
                      chatUsersPopup: true,
                    })
                  : forwardMessagesWebhookSelectable
                  ? isButtonsPopupForForwardMessagesWebhook
                    ? handleMessagesToWebhookPopup(true)
                    : forwardMessagesToWebhook(forwardMessagesWebhookList)
                  : null
              }
            >
              <ShortcutOutlined color="primary" sx={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                if (forwardMessage.visible) {
                  closeForwardMessage();
                } else if (forwardMessagesWebhookSelectable) {
                  resetForwardMessagesToWebhook();
                }
              }}
            >
              <Close color="primary" sx={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <div ref={smilesPanelContainerRef} />
      {/* <SmilesPanelBottom
        textareaRef={textareaRef}
        isOpen={isSmileBottomPanelOpen}
        // textareaValue={textareaValue}
        // setTextareaValue={setTextareaValue}
        toggleSmilesPanelBottom={toggleSmilesPanelBottom}
      /> */}
      <form className="inputForm">
        {/* <div className="d-flex chat-input-actions"> */}
        <Grid
          container
          spacing={1.5}
          alignItems={applicationType === "web" ? "flex-end" : null}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              rowSpacing={1.5}
              flexWrap={"wrap-reverse"}
            >
              <Grid item>
                <div className="chat-input-actions__top">
                  <button
                    type="button"
                    className={`chat-input-actions__whatsapp chat-input-actions__top-btn ${
                      sendToPlatformsStatus === "whatsapp" ? "active" : ""
                    }`}
                    onClick={() => switchToChatType("whatsapp")}
                  >
                    <span className="icon">
                      {/* <img src="${whatsappChatSmallIcon}"alt="" /> */}
                      <svg
                        fill="#fff"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          <path d="M11.42 9.49c-.19-.09-1.1-.54-1.27-.61s-.29-.09-.42.1-.48.6-.59.73-.21.14-.4 0a5.13 5.13 0 0 1-1.49-.92 5.25 5.25 0 0 1-1-1.29c-.11-.18 0-.28.08-.38s.18-.21.28-.32a1.39 1.39 0 0 0 .18-.31.38.38 0 0 0 0-.33c0-.09-.42-1-.58-1.37s-.3-.32-.41-.32h-.4a.72.72 0 0 0-.5.23 2.1 2.1 0 0 0-.65 1.55A3.59 3.59 0 0 0 5 8.2 8.32 8.32 0 0 0 8.19 11c.44.19.78.3 1.05.39a2.53 2.53 0 0 0 1.17.07 1.93 1.93 0 0 0 1.26-.88 1.67 1.67 0 0 0 .11-.88c-.05-.07-.17-.12-.36-.21z" />
                          <path d="M13.29 2.68A7.36 7.36 0 0 0 8 .5a7.44 7.44 0 0 0-6.41 11.15l-1 3.85 3.94-1a7.4 7.4 0 0 0 3.55.9H8a7.44 7.44 0 0 0 5.29-12.72zM8 14.12a6.12 6.12 0 0 1-3.15-.87l-.22-.13-2.34.61.62-2.28-.14-.23a6.18 6.18 0 0 1 9.6-7.65 6.12 6.12 0 0 1 1.81 4.37A6.19 6.19 0 0 1 8 14.12z" />
                        </g>
                      </svg>
                    </span>
                    {localization.sendType.whatsapp}
                  </button>
                  <Tooltip
                    title={
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: localization.sendType.noteTooltip,
                        }}
                      />
                    }
                    placement="top"
                  >
                    <button
                      type="button"
                      className={`chat-input-actions__record chat-input-actions__top-btn ${
                        sendToPlatformsStatus === "note" ? "active" : ""
                      }`}
                      onClick={() => switchToChatType("note")}
                    >
                      <span className="icon">
                        <img src={recordChatSmallIcon} alt="" />
                      </span>
                      {localization.sendType.note}
                    </button>
                  </Tooltip>

                  <div
                    className="funner-tooltip funner-tooltip--top"
                    style={{ display: "none" }}
                  >
                    <button
                      type="button"
                      className={`chat-input-actions__facebook chat-input-actions__top-btn _disabled-element ${
                        sendToPlatformsStatus === "facebook" ? "active" : ""
                      }`}
                      style={{ pointerEvents: "none" }}
                      // onClick={() => switchToChatType("facebook")}
                    >
                      <span className="icon">
                        <svg
                          width={24}
                          height={25}
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_348_9822)">
                            <path
                              d="M10.5266 19.633L10.5237 19.6323C10.2587 19.5614 9.97018 19.5783 9.71022 19.6942C9.7097 19.6945 9.70919 19.6947 9.70867 19.6949L7.92483 20.4812L7.92471 20.4813C7.89173 20.4958 7.8557 20.5021 7.81976 20.4994C7.78383 20.4968 7.74909 20.4853 7.7186 20.4661C7.68812 20.4469 7.66282 20.4205 7.64493 20.3892C7.62705 20.3579 7.61712 20.3228 7.61602 20.2867L7.61601 20.2866L7.56662 18.6887C7.5666 18.6879 7.56659 18.6872 7.56657 18.6864C7.5581 18.3434 7.40233 18.0313 7.15897 17.8141C5.51474 16.343 4.5 14.2107 4.5 11.7298C4.5 7.00868 8.18865 3.5 13 3.5C17.8112 3.5 21.5 7.01077 21.5 11.732C21.5 16.4531 17.8113 19.9618 13 19.9618C12.1334 19.9618 11.3048 19.8463 10.5266 19.633Z"
                              stroke="#fff"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.4781 9.87641L8.06367 13.4408C7.83147 13.7831 8.28354 14.1674 8.62876 13.9227C8.97398 13.6779 11.222 12.0908 11.222 12.0908C11.3073 12.0304 11.4114 11.9976 11.5186 11.9973C11.6257 11.9969 11.7301 12.0291 11.8159 12.0888L13.7371 13.4293C13.8734 13.5245 14.0294 13.5925 14.1953 13.6288C14.3612 13.6651 14.5334 13.669 14.701 13.6402C14.8686 13.6115 15.028 13.5507 15.1691 13.4617C15.3102 13.3728 15.4299 13.2575 15.5208 13.1234L17.9373 9.5609C18.1674 9.21861 17.7154 8.83234 17.3701 9.07711L14.7769 10.909C14.6916 10.9693 14.5875 11.0021 14.4803 11.0025C14.3732 11.0028 14.2688 10.9707 14.1831 10.9109L12.2618 9.57046C12.1255 9.47522 11.9695 9.4073 11.8036 9.371C11.6377 9.3347 11.4655 9.33081 11.2979 9.35956C11.1303 9.38831 10.9709 9.44908 10.8298 9.53804C10.6887 9.627 10.569 9.74222 10.4781 9.87641Z"
                              stroke="#fff"
                              strokeWidth="0.75"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_348_9822">
                              <rect
                                width={24}
                                height={24}
                                fill="white"
                                transform="translate(0 0.615234)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      {localization.sendType.facebook}
                    </button>
                    <div className="funner-tooltip__text">
                      <div className="funner-tooltip__text-content">
                        {localization.comingSoon}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="chat-input-actions__instagram chat-input-actions__top-btn"
                    style={{ display: "none" }}
                  >
                    <span className="icon">
                      <img src={instagramRounded} alt="" />
                    </span>
                    {localization.sendType.instagram}
                  </button>
                </div>
              </Grid>

              {applicationType !== "extension" && (
                <Grid item>
                  <div
                    className="chat-input-actions__bottom-right-buttons"
                    id="funner-global__buttons-interaction-wrapper"
                  >
                    <Divider
                      orientation="vertical"
                      flexItem={true}
                      sx={{ mr: 0.5, my: 0.5 }}
                    />
                    <Tooltip
                      title={localization.iconButtons.templates.tooltip}
                      placement="top"
                    >
                      <span>
                        <IconButton
                          onClick={() => toggleAccordion()}
                          size={"small"}
                          color="primary"
                          sx={{
                            width: 34,
                            height: 34,
                            transition: "color 0.15s ease 0s",
                          }}
                          disabled={
                            sendToPlatformsStatus === "note" ||
                            !selectedChatUser
                          }
                        >
                          <SvgIcon sx={{ width: 20, height: 20 }}>
                            <svg
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.83335 2.49992H3.33335V4.99992H1.66669V0.833252H5.83335V2.49992ZM18.3334 4.99992V0.833252H14.1667V2.49992H16.6667V4.99992H18.3334ZM5.83335 17.4999H3.33335V14.9999H1.66669V19.1666H5.83335V17.4999ZM16.6667 14.9999V17.4999H14.1667V19.1666H18.3334V14.9999H16.6667ZM14.1667 4.99992H5.83335V14.9999H14.1667V4.99992ZM15.8334 14.9999C15.8334 15.9166 15.0834 16.6666 14.1667 16.6666H5.83335C4.91669 16.6666 4.16669 15.9166 4.16669 14.9999V4.99992C4.16669 4.08325 4.91669 3.33325 5.83335 3.33325H14.1667C15.0834 3.33325 15.8334 4.08325 15.8334 4.99992V14.9999ZM12.5 6.66658H7.50002V8.33325H12.5V6.66658ZM12.5 9.16658H7.50002V10.8333H12.5V9.16658ZM12.5 11.6666H7.50002V13.3333H12.5V11.6666Z" />
                            </svg>
                          </SvgIcon>
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Divider
                      orientation="vertical"
                      flexItem={true}
                      sx={{ mx: 0.5, my: 0.5 }}
                    />
                    <IconButton
                      // component="label"
                      // htmlFor={`${chatType}-contained-button-file`}
                      size="small"
                      color="primary"
                      sx={{
                        width: 34,
                        height: 34,
                        transition: "color 0.15s ease 0s",
                      }}
                      disabled={
                        sendToPlatformsStatus === "note" ||
                        (sendToPlatformsStatus !== "note" && !isActive)
                      }
                      onClick={handleUploadMenu}
                    >
                      <SvgIcon sx={{ width: 24, height: 24 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="currentColor"
                        >
                          <g id="FilePresentOutlined">
                            <path
                              id="Vector"
                              d="M15.1666 2.16675H6.49998C5.30831 2.16675 4.33331 3.14175 4.33331 4.33341V21.6667C4.33331 22.8584 5.30831 23.8334 6.49998 23.8334H19.5C20.6916 23.8334 21.6666 22.8584 21.6666 21.6667V8.66675L15.1666 2.16675ZM19.5 21.6667H6.49998V4.33341H15.1666V8.66675H19.5V21.6667ZM13 18.4167C11.8083 18.4167 10.8333 17.4417 10.8333 16.2501V10.2917C10.8333 9.98841 11.0716 9.75008 11.375 9.75008C11.6783 9.75008 11.9166 9.98841 11.9166 10.2917V16.2501H14.0833V10.2917C14.0833 8.79675 12.87 7.58342 11.375 7.58342C9.87998 7.58342 8.66665 8.79675 8.66665 10.2917V16.2501C8.66665 18.6442 10.6058 20.5834 13 20.5834C15.3941 20.5834 17.3333 18.6442 17.3333 16.2501V11.9167H15.1666V16.2501C15.1666 17.4417 14.1916 18.4167 13 18.4167Z"
                            />
                          </g>
                        </svg>
                      </SvgIcon>
                      <label
                        htmlFor={`${chatType}-contained-button-file`}
                        style={{ display: "none" }}
                        ref={uploadLabelRef}
                      >
                        <input
                          type="file"
                          id={`${chatType}-contained-button-file`}
                          multiple={true}
                          onChange={(e) => {
                            handleUpload(e.target.files);
                            e.target.value = null;
                          }}
                        />
                      </label>
                    </IconButton>
                    <Divider
                      orientation="vertical"
                      flexItem={true}
                      sx={{ mx: 0.5, my: 0.5 }}
                    />
                    <IconButton
                      onClick={toggleSmilesPanelBottom}
                      size={"small"}
                      color="primary"
                      sx={{
                        width: 34,
                        height: 34,
                        transition: "color 0.15s ease 0s",
                      }}
                      disabled={!isActive}
                    >
                      <SvgIcon sx={{ width: 24, height: 24 }}>
                        <svg
                          viewBox="0 0 26 26"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12.9892 2.16675C7.00919 2.16675 2.16669 7.02008 2.16669 13.0001C2.16669 18.9801 7.00919 23.8334 12.9892 23.8334C18.98 23.8334 23.8334 18.9801 23.8334 13.0001C23.8334 7.02008 18.98 2.16675 12.9892 2.16675ZM13 21.6667C8.21169 21.6667 4.33335 17.7884 4.33335 13.0001C4.33335 8.21175 8.21169 4.33341 13 4.33341C17.7884 4.33341 21.6667 8.21175 21.6667 13.0001C21.6667 17.7884 17.7884 21.6667 13 21.6667Z" />
                          <path d="M13 19.5001C15.47 19.5001 17.5717 17.7017 18.4167 15.1667H7.58335C8.42835 17.7017 10.53 19.5001 13 19.5001Z" />
                          <path d="M9.20835 11.9167C10.1058 11.9167 10.8334 11.1892 10.8334 10.2917C10.8334 9.39429 10.1058 8.66675 9.20835 8.66675C8.31089 8.66675 7.58335 9.39429 7.58335 10.2917C7.58335 11.1892 8.31089 11.9167 9.20835 11.9167Z" />
                          <path d="M16.7917 11.9167C17.6891 11.9167 18.4167 11.1892 18.4167 10.2917C18.4167 9.39429 17.6891 8.66675 16.7917 8.66675C15.8942 8.66675 15.1667 9.39429 15.1667 10.2917C15.1667 11.1892 15.8942 11.9167 16.7917 11.9167Z" />
                        </svg>
                      </SvgIcon>
                    </IconButton>
                    <Divider
                      orientation="vertical"
                      flexItem={true}
                      sx={{ mx: 0.5, my: 0.5 }}
                    />
                    {businessInfo?.CompanyType === "fireberry" && (
                      <Fragment>
                        <IconButton
                          size={"small"}
                          color="primary"
                          sx={{ width: 34, height: 34 }}
                          LinkComponent={"a"}
                          href={
                            selectedChatUser?.fireberryAccountLink
                              ? selectedChatUser.fireberryAccountLink
                              : `https://app.powerlink.co.il/app/views/${
                                  businessInfo?.LinkToObjectNum === "second"
                                    ? "2"
                                    : "1"
                                }?q=${selectedChatUser?.UserDisplayPhoneNumber?.replace(
                                  "972",
                                  "0",
                                )}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {/* {selectedChatUser?.fireberryAccountLink &&
                          !selectedChatUser.fireberryAccountLink.includes(
                            "?q=",
                          ) ? (
                            <img
                              src={powerlinkIcon}
                              style={{ width: 22, height: 22 }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={powerlinkIconOrange}
                              style={{ width: 22, height: 22 }}
                              alt=""
                            />
                          )} */}
                          <img
                            src={powerlinkIcon}
                            style={{ width: 22, height: 22 }}
                            alt=""
                          />
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem={true}
                          sx={{ ml: 0.5, my: 0.5 }}
                        />
                      </Fragment>
                    )}

                    {businessInfo.CompanyType === "priority" && (
                      <div
                        className={`chat-input-actions__docs-menu-wrapper material-dropdown material-dropdown-common ${
                          sendDocsMenuVisible ? "active" : ""
                        }`}
                        ref={sendDocsMenuRef}
                      >
                        <IconButton
                          onClick={() =>
                            handleSendDocsMenuVisible(!sendDocsMenuVisible)
                          }
                          size={"small"}
                          color="primary"
                          ref={sendDocsBtnRef}
                          sx={{
                            width: 34,
                            height: 34,
                            transition: "color 0.15s ease 0s",
                          }}
                        >
                          <img
                            src={priorityDocLogo}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                            }}
                          />
                        </IconButton>
                        <ul
                          className="material-dropdown__body"
                          style={{
                            bottom: "calc(100% + 3px)",
                            top: "auto",
                            width: 220,
                            right: "-24px",
                            borderRadius: 4,
                            boxShadow:
                              "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                          }}
                        >
                          {businessInfo?.CompanyType === "priority" && (
                            <li
                              style={{
                                display: "flex",
                                alignItems: "center, gap: 9px",
                              }}
                              className="_priority-send-docs"
                              onClick={() => {
                                handlePrioritySendDocsWindow(true);
                                handleSendDocsMenuVisible(false);
                              }}
                            >
                              <div
                                className="funner-icon"
                                style={{
                                  width: 23,
                                  flex: "0 0 23px",
                                  height: 20,
                                  marginLeft: 8,
                                }}
                              >
                                <img src={priorityLetterLogo} alt="" />
                              </div>
                              מסמך מפריוריטי
                            </li>
                          )}
                        </ul>
                      </div>
                    )}

                    {additionalLink.enabled && additionalLink.url && (
                      <Fragment>
                        <IconButton
                          size={"small"}
                          color="primary"
                          sx={{ width: 34, height: 34 }}
                          LinkComponent={"a"}
                          href={additionalLink.url.replaceAll(
                            "{phoneNumber}",
                            selectedChatUser?.UserDisplayPhoneNumber,
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {additionalLink.icon ? (
                            <img
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                                display: "block",
                              }}
                              src={additionalLink.icon}
                              alt=""
                            />
                          ) : (
                            <LinkOutlined />
                          )}
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem={true}
                          sx={{ ml: 0.5, my: 0.5 }}
                        />
                      </Fragment>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SendMessageForm
              funnerUsers={funnerUsers}
              textareaRef={textareaRef}
              // setTextareaValue={setTextareaValue}
              isTemplateSending={isTemplateSending}
              // textareaValue={textareaValue}
              sendToPlatformsStatus={sendToPlatformsStatus}
              isActive={isActive}
              selectedChatUser={selectedChatUser}
              handleUpload={handleUpload}
              userConfig={userConfig}
              resetTemplateFromChat={resetTemplateFromChat}
              toggleAccordion={toggleAccordion}
              chatType={chatType}
              toggleSmilesPanelBottom={toggleSmilesPanelBottom}
              companyType={companyType}
              linkToObjectNum={businessInfo?.linkToObjectNum}
              sendDocsMenuVisible={sendDocsMenuVisible}
              sendDocsMenuRef={sendDocsMenuRef}
              handleSendDocsMenuVisible={handleSendDocsMenuVisible}
              sendDocsBtnRef={sendDocsBtnRef}
              handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
              sendUpload={sendUpload}
              sendUploadPriority={sendUploadPriority}
              isMessageSending={isMessageSending}
              handleForm={handleForm}
              smilesPanelContainerRef={smilesPanelContainerRef}
              isSmileBottomPanelOpen={isSmileBottomPanelOpen}
            />
          </Grid>
        </Grid>

        {/* </div> */}
      </form>

      <Menu
        open={Boolean(uploadMenuAnchor)}
        anchorEl={uploadMenuAnchor}
        onClose={() => setUploadMenuAnchor(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => uploadFromMediaGallery()}>
          Via gallery
        </MenuItem>
        <MenuItem onClick={() => uploadFromComputer()}>
          Upload from computer
        </MenuItem>
      </Menu>
      <Dialog
        open={Boolean(chooseFromGallery)}
        onClose={() => setChooseFromGallery(false)}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle justifyContent={"flex-end"}>
          <IconButton onClick={() => setChooseFromGallery(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FilesListLayout
            type={chooseFromGallery}
            companyId={null}
            FunnerUserId={null}
            noActions={true}
            onClickAction={(file) => {
              handleDirectFileUpload(file);
              setChooseFromGallery(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ChatInputArea;
